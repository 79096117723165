import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import FabricDetailsTabPane from './FabricDetailsTabPane';

export default function FabricDetailsDialog({ closeMiniView, isArticleSearch, onClose, direction }) {
  const maxWidth = 'lg'; // sm, md, lg
  const isMobile = useMediaQuery('(max-width:800px)');
  const { t } = useTranslation();

  const handleClose = () => {
    closeMiniView();
    onClose();
  };

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        maxWidth={maxWidth}
        open
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{ textTransform: 'uppercase' }}>
          {t('Ausgewählte Stoffe')}
        </DialogTitle>
        <DialogActions>
          <IconButton style={closeButton} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <FabricDetailsTabPane style={{ padding: 0 }} isArticleSearch={isArticleSearch} direction={direction} />
        </DialogContent>
      </Dialog>
    </>
  );
}

FabricDetailsDialog.propTypes = {
  closeMiniView: PropTypes.func.isRequired,
  direction: PropTypes.string,
};

FabricDetailsDialog.defaultProps = {
  direction: 'left',
};
