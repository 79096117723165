/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useData } from '../DataProvider';
import HamburgerMenu from './HamburgerMenu';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  navLink: {
    color: '#414141',
    textDecoration: 'none',
    fontSize: '20px',
    '@media (max-width:1240px)': {
      fontSize: '18px',
    },
    '@media (max-width:780px)': {
      fontSize: '15px',
    },
    '@media (max-width:640px)': {
      fontSize: '12px',
    },
  },
  bgHeader: {
    background: '#F8F8F8',
  },
  linkStyles: {
    fontWeight: '600',
  },
  alignRight: {
    textAlign: 'right',
  },
}));

export default function NavigationBar() {
  const { t } = useTranslation();

  const classes = useStyles();
  const { jsonData, queryParams } = useData();
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 960 });
  const isLandscape = useMediaQuery({ minWidth: 851 });
  const isPortrait = useMediaQuery({ maxWidth: 850 });
  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  const [linkStatus, setLinkStatus] = useState();
  const [showShadowSim, setShowShadowSim] = useState(true);
  const navigate = useNavigate();

  const Portrait = ({ children }) => (isPortrait ? children : null);

  const Landscape = ({ children }) => (isLandscape ? children : null);

  const handleClick = (index) => {
    setLinkStatus(Number(index));
    localStorage.setItem('activePage', index);

    if (index === 0) {
      navigate(`/${queryParams}`);
    }
    if (index === 1) {
      navigate(`/compare${queryParams}`);
    }
    if (index === 2) {
      navigate(`/shadow${queryParams}`);
    }
  };

  useEffect(() => {
    const index = localStorage.getItem('activePage') || 0;
    setLinkStatus(Number(index));

    if (jsonData && jsonData.customer.show_shadow_sim) {
      if (jsonData.customer.show_shadow_sim === 'false') setShowShadowSim(false);
    }
  }, [setLinkStatus, jsonData]);

  return (
    <>
      <Portrait>
        <HamburgerMenu showShadowSim={showShadowSim} />
      </Portrait>
      <Landscape>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.bgHeader}>
          <Grid item xs={4}>
            <Box p={1}>
              <Button
                className={clsx(classes.navLink, linkStatus === 0 && classes.linkStyles)}
                onClick={() => handleClick(0)}
                style={{ textTransform: 'uppercase', padding: 0, backgroundColor: 'transparent' }}
              >
                <div style={isPhone && isLandscape ? { fontSize: 12 } : null}>DESIGN SELECTOR</div>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.box" p={1} className={!isMobile ? classes.alignRight : null}>
              <Button
                className={clsx(classes.navLink, linkStatus === 1 && classes.linkStyles)}
                style={{ textTransform: 'uppercase', padding: 0, backgroundColor: 'transparent' }}
                onClick={() => handleClick(1)}
              >
                {t('fabric_compare')}
              </Button>
            </Box>
          </Grid>
          {showShadowSim && (
            <Grid item xs={4}>
              <Box bgcolor="primary.box" p={1} className={!isMobile ? classes.alignRight : null}>
                <Button
                  className={clsx(classes.navLink, linkStatus === 2 && classes.linkStyles)}
                  style={{ textTransform: 'uppercase', padding: 0, backgroundColor: 'transparent' }}
                  onClick={() => handleClick(2)}
                >
                  {t('shadow_sim')}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Landscape>
    </>
  );
}
