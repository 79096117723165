import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useData } from '../../DataProvider';
import ScenesPaginationCard from './ScenesPaginationCard';

export default function ScenePagination({
  todosPerPage,
  resetCounter,
  setReset,
  shouldUpdateScene = true,
  setLocalCardId,
  localCardId,
}) {
  const { selectedScenePage, setSelectedScenePage, setSelectedSceneIndex, selectedSceneIndex, allScenes } = useData();
  const allScenesArray = [];
  const { t } = useTranslation();
  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  if (allScenes) {
    allScenes.forEach((element) => {
      allScenesArray.push(element);
    });
  }

  const [localPageIndex, setLocalPageIndex] = useState(1);

  const indexOfLastTodo = shouldUpdateScene ? selectedScenePage * todosPerPage : localPageIndex * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentElements = allScenesArray.slice(indexOfFirstTodo, indexOfLastTodo);

  function findSelectedId() {
    return allScenesArray[selectedSceneIndex].scene.id;
  }

  function updateScene(id) {
    const index = allScenesArray.findIndex((elem) => elem.scene.id === id);
    setSelectedSceneIndex(index);
  }

  const renderCards = currentElements.map((cardObject) => (
    <Grid item key={cardObject.scene.id}>
      <ScenesPaginationCard
        key={cardObject.id}
        cardInfos={cardObject}
        shouldSceneBeUpdated={shouldUpdateScene}
        updateScene={updateScene}
        findSelectedId={findSelectedId}
        localCardId={localCardId}
        setLocalCardId={setLocalCardId}
      />
    </Grid>
  ));

  const buttonStyles = {
    color: ' #414141',
    textTransform: 'lowercase',
  };

  const bottomNavBar = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  useEffect(() => {
    if (resetCounter) {
      setSelectedScenePage(1);
      setReset(false);
    }
  });

  function next() {
    if (shouldUpdateScene) setSelectedScenePage(selectedScenePage + 1);
    else setLocalPageIndex(localPageIndex + 1);
  }

  function previous() {
    if (shouldUpdateScene) setSelectedScenePage(selectedScenePage - 1);
    else setLocalPageIndex(localPageIndex - 1);
  }

  return (
    <div>
      <Grid
        container
        justify="center"
        spacing={2}
        style={{ marginBottom: 10, paddingLeft: isPhone ? 0 : 40, paddingRight: isPhone ? 0 : 40 }}
      >
        {renderCards}
      </Grid>
      <div style={bottomNavBar}>
        <div>
          {indexOfFirstTodo > 0 ? (
            <Button onClick={previous} style={buttonStyles}>
              <ArrowBackIosIcon />
              {indexOfLastTodo >= allScenesArray.length ? allScenesArray.length : indexOfLastTodo} von{' '}
              {allScenesArray.length}
            </Button>
          ) : null}
        </div>
        <div>
          {indexOfLastTodo < allScenesArray.length ? (
            <Button onClick={next} style={buttonStyles}>
              {t('next_btn')}
              <ArrowForwardIosIcon />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

ScenePagination.propTypes = {
  resetCounter: PropTypes.number,
  setReset: PropTypes.func,
  todosPerPage: PropTypes.number.isRequired,
  shouldUpdateScene: PropTypes.bool,
};

ScenePagination.defaultProps = {
  shouldUpdateScene: true,
  resetCounter: null,
  setReset: null,
};
