import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useData } from '../../DataProvider';
import { getSelectedItemById, replaceSpecialChars } from '../../../util/util';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 80,
    margin: 'auto',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  MuiCardContent: {
    root: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  media: {
    paddingTop: '46.25%',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
    '&:not(:first-of-type)': {
      marginLeft: -theme.spacing(1),
    },
  },
  largeIcon: {
    width: 10,
    height: 10,
  },
  cardBorder: {
    boxShadow: (props) => `0px 0px 0px 1px ${props.selectColor} inset`,
  },
}));

export default function MiniPaginationCard({ cardInfos, id, hotspotIndex, hotspotId, direction }) {
  const {
    watchMap,
    updateCard,
    selectedItems,
    selectedItemsPerIndex,
    selectedItemsPerIndexRight,
    setSelectedItemsPerIndex,
    setSelectedItemsPerIndexRight,
    setSelectedItems,
    selectedItemsRight,
    selectColor,
  } = useData();
  const classes = useStyles({ selectColor });
  const isOnWatchMap = !!watchMap.get(id);
  const [isFavorite, setFavorite] = React.useState(isOnWatchMap);

  const fabricNumber =
    cardInfos.product.customer_set_alt_article_number && cardInfos.product.customer_set_alt_article_number.length > 3
      ? cardInfos.product.customer_set_alt_article_number
      : cardInfos.product.post_title;

  const concatFabricNumber =
    fabricNumber.length === 6 ? `${fabricNumber.substring(0, 3)} ${fabricNumber.substring(3, 6)}` : fabricNumber;

  const fabricNameItem = replaceSpecialChars(cardInfos.product.post_title);
  const fabricImageURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_FOLDER}${fabricNameItem}.jpg`;

  let isSelected = false;
  if (hotspotIndex !== undefined) {
    if (direction === 'left')
      isSelected = cardInfos.product.post_title === getSelectedItemById(selectedItems, hotspotId).fabric.post_title;
    else if (direction === 'right')
      isSelected =
        cardInfos.product.post_title === getSelectedItemById(selectedItemsRight, hotspotId).fabric.post_title;
  }
  const selectFabric = () => {
    let items;
    if (direction === 'left') items = { ...selectedItems };
    else if (direction === 'right') items = { ...selectedItemsRight };

    if (hotspotId) getSelectedItemById(items, hotspotId).fabric = cardInfos.product;
    else {
      items[0].fabric = cardInfos.product;
    }

    if (direction === 'left') {
      selectedItemsPerIndex[hotspotIndex].fabric = cardInfos.product;
      selectedItemsPerIndex[hotspotIndex].subgroupId = hotspotId;
      setSelectedItemsPerIndex({ ...selectedItemsPerIndex });
      setSelectedItems(items);
    }
    if (direction === 'right') {
      selectedItemsPerIndexRight[hotspotIndex].fabric = cardInfos.product;
      selectedItemsPerIndexRight[hotspotIndex].subgroupId = hotspotId;
      setSelectedItemsPerIndexRight({ ...selectedItemsPerIndexRight });
      setSelectedItems(items);
    }
  };

  function updateCardInfo(favoriteState) {
    const newCardInfo = cardInfos;
    newCardInfo.isFavorite = favoriteState;
    updateCard(id, newCardInfo);
  }

  return (
    <div className="App">
      <Card className={clsx(classes.card, isSelected ? classes.cardBorder : null)} onClick={selectFabric}>
        <CardActionArea>
          <CardMedia className={classes.media} image={fabricImageURL} />
          <CardContent className={classes.cardContent}>
            <div
              style={{
                display: 'flex',
                whiteSpace: 'pre',
                justifyContent: 'end',
                alignContent: 'space-between',
              }}
            >
              <Typography
                className="MuiTypography--heading"
                variant="h6"
                gutterBottom
                style={{ fontSize: '0.8rem', textTransform: 'capitalize', marginLeft: '5px' }}
              >
                {concatFabricNumber}
              </Typography>
              <IconButton
                aria-label="like"
                style={{
                  color: selectColor,
                  padding: 0,
                  paddingLeft: 5,
                  minHeight: 0,
                  minWidth: 0,
                }}
                onClick={() => {
                  setFavorite(!isFavorite);
                  updateCardInfo(!isFavorite);
                }}
              >
                {isFavorite ? (
                  <FavoriteIcon fontSize="small" style={{ color: selectColor }} />
                ) : (
                  <FavoriteBorderIcon style={{ color: selectColor }} fontSize="small" />
                )}
              </IconButton>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

MiniPaginationCard.propTypes = {
  cardInfos: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
};
