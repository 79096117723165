import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from 'react-responsive';
import { useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { IconButton, Snackbar } from '@material-ui/core';
import CustomerInquiryForm from './CustomerInquiryForm';
import { getTriggerInquiryDialog } from '../Subjects/subjects';
import { useData } from '../DataProvider';

export default function CustomerInquiryDialog({ margin }) {
  const [open, setOpen] = useState(false);
  const [cardId, setCardId] = useState(-1);
  const { watchMap, userData } = useData();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const maxWidth = isTabletOrMobile ? 'sm' : 'md'; // sm, md, lg
  const childRef = useRef();
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setCardId(-1);
    setOpen(true);
  };

  const sendCustomerRequest = (formData) => {
    const fabrics = [];
    watchMap.forEach((v) => {
      fabrics.push(v.product.post_title);
    });

    const email = userData.data.data.userData.application_contact_email || '';
    const firstname = formData[0].value;
    const lastname = formData[1].value;
    const subject = formData[2].value;
    const note = formData[3].value;

    const message = `${t('dear')}, \n\n ${t('req_info')} \n\n ${t('fabs')}: ${fabrics.join(', ')} \n ${t(
      'note',
    )}: ${note} \n ${t('firstname')}: ${firstname} \n ${t('lastname')}: ${lastname} \n \n ${t('thank')} \n \n ${t(
      'greets',
    )} \n ${firstname} ${lastname}`;

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${
      encodeURIComponent(message) || ''
    }`;
  };

  const handleClose = () => {
    childRef.current.submit();
  };

  getTriggerInquiryDialog().subscribe((id) => {
    setCardId(id);
    setOpen(true);
  });

  return (
    <>
      {userData.data.data.userData.application_enable_contact_email === 'true' ? (
        <Button
          style={{
            textTransform: 'capitalize',
            marginLeft: margin === 'true' ? 40 : 0,
            marginTop: margin === 'true' ? 25 : 0,
          }}
          onClick={handleClickOpen}
        >
          {t('request')}
        </Button>
      ) : (
        ''
      )}
      <Dialog maxWidth={maxWidth} open={open} onClose={() => setOpen(false)} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">{t('request')}</DialogTitle>
        <div style={{ position: 'absolute', right: '1%', top: '1%' }}>
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <CustomerInquiryForm onSubmit={(formData) => sendCustomerRequest(formData)} ref={childRef} cardId={cardId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
          <Button onClick={handleClose}>{t('send_request')}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Success"
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      />
    </>
  );
}
