import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';

const ITEM_HEIGHT = 48;

const HamburgerMenu = ({ showShadowSim }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuName, setMenuName] = useState('');
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { queryParams } = useData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const redirect = (index) => {
    if (index === 0) {
      navigate(`/${queryParams}`);
    }
    if (index === 1) {
      navigate(`/compare/${queryParams}`);
    }
    if (index === 2) {
      navigate(`/shadow/${queryParams}`);
    }
  };

  useEffect(() => {
    const path = window.location.pathname.replace(/^\/([^/]*).*$/, '$1');

    if (path === '') {
      setSelected(0);
      setMenuName(`DESIGN SELECTOR`);
    } else if (path === 'compare') {
      setSelected(1);
      setMenuName(t('fabric_compare'));
    }
    if (path === 'shadow') {
      setSelected(2);
      setMenuName(t('shadow_sim'));
    }
  }, [t]);

  const handleClose = (event) => {
    setAnchorEl(null);

    if (event.target.value || event.target.value === 0) {
      setSelected(event.target.value);

      if (event.target.value === 0) {
        setSelected(0);
        setMenuName(`DESIGN SELECTOR`);
      } else if (event.target.value === 1) {
        setSelected(1);
        setMenuName(t('fabric_compare'));
      }
      if (event.target.value === 2) {
        setSelected(2);
        setMenuName(t('shadow_sim'));
      }
      redirect(event.target.value);
    }
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '100%',
          },
        }}
      >
        <MenuItem style={{ textTransform: 'uppercase' }} selected={selected === 0} value={0} onClick={handleClose}>
          DESIGN SELECTOR
        </MenuItem>
        <MenuItem style={{ textTransform: 'uppercase' }} selected={selected === 1} value={1} onClick={handleClose}>
          {t('fabric_compare')}
        </MenuItem>
        {showShadowSim && (
          <MenuItem style={{ textTransform: 'uppercase' }} selected={selected === 2} value={2} onClick={handleClose}>
            {t('shadow_sim')}
          </MenuItem>
        )}
      </Menu>
      <b style={{ textTransform: 'uppercase' }}>{menuName}</b>
    </div>
  );
};

export default HamburgerMenu;
