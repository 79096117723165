/* eslint-disable no-param-reassign */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Fab,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import QRCode from 'qrcode.react';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  },
}));

const WhiteLabelSectionComponent = observer(({ userSelection, userConfig, saveWL }) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const defaultColor = theme.palette.select.color;
  const classes = useStyles({ defaultColor });

  const handleUpload = (e) => {
    // eslint-disable-next-line prefer-destructuring
    userSelection.whiteLabelAppLogo = e.target.files[0];
    userSelection.userLogoURL = URL.createObjectURL(e.target.files[0]);
  };

  return (
    <Card
      className={classes.root}
      variant="outlined"
      style={{
        width: '100%',
        fontWeight: 700,
        marginTop: '1%',
        marginBottom: '1%',
      }}
    >
      <CardContent>
        <Typography
          color="textSecondary"
          style={{
            color: '#414141',
            fontSize: '20px',
            marginBottom: '1%',
            textAlign: 'left',
          }}
        >
          White Label App Content
        </Typography>

        <QRCode
          style={{ margin: '0 auto', display: 'flex' }}
          value={`${process.env.REACT_APP_WHITELABEL_URL}?userId=${userConfig.userId}`}
        />
        <br />
        <Link
          target="_blank"
          rel="noopener"
          href={`${process.env.REACT_APP_WHITELABEL_URL}?userId=${userConfig.userId}`}
        >
          {t('wl_app_link')}
        </Link>
        <br />
        <TextField
          id="whitelabelAppText"
          name="whiteLabelAppText"
          label="Whitelabel App Text"
          variant="outlined"
          className={classes.textField}
          size="small"
          value={userSelection.whitelabelAppText}
          onChange={action((e) => {
            userSelection.whitelabelAppText = e.target.value;
          })}
        />
        <Grid container item xs={2} alignItems="center" alignContent="center" justify="center">
          <Grid item xs={12} stlye={{ border: '1px solid black' }}>
            <Typography variant="body2">Logo</Typography>
            <br />
            <Paper elevation={1} style={{ width: 200 }}>
              {userSelection.userLogoURL && (
                <img alt="profilePicture" width="100%" className={classes.media} src={userSelection.userLogoURL} />
              )}
            </Paper>
            <br />
            <label htmlFor="contained-button-file">
              <Fab component="span" className={classes.button}>
                <AddPhotoAlternateIcon />
              </Fab>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleUpload}
              />
            </label>
          </Grid>
        </Grid>
        <CardActions style={{ float: 'right', width: '55%' }}>
          <Button onClick={saveWL} className={classes.buttonStyles}>
            {t('save')}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
});

export default WhiteLabelSectionComponent;
