import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import blackAndWhite from '../../assets/colors/ic_black_white.svg';
import blue from '../../assets/colors/icon-pattern-uni.png';
import brown from '../../assets/colors/ic_brown.svg';
import green from '../../assets/colors/ic_green.svg';
import gray from '../../assets/colors/ic_grey.svg';
import orange from '../../assets/colors/ic_orange.svg';
import yellow from '../../assets/colors/ic_yellow.svg';
import red from '../../assets/colors/ic_red.svg';

import { useData } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    display: 'contents',
  },
}));

export default function ColorFilter() {
  const { filterItems, setFilterItems, jsonData, selectColor, selectedSceneIndex, allScenes } = useData();
  const isPortait = useMediaQuery({ maxWidth: 950, orientation: 'portrait' });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPatternExpanded, setIsPatternExpanded] = useState(false);
  const classes = useStyles();
  const [colorCounter, setColorCounter] = useState(0);
  const [patternCounter, setPatternCounter] = useState(0);
  const { t } = useTranslation();

  const colorIconArray = {
    1310: blue,
    1313: brown,
    1308: yellow,
    1376: gray,
    1309: green,
    1377: orange,
    1307: red,
    1312: blackAndWhite,
    6059: gray,
  };

  function handleOnChange(expanded) {
    setIsExpanded(expanded);
  }

  function handlePatternExpanded(expanded) {
    setIsPatternExpanded(expanded);
  }

  const handleColorChange = (color) => {
    const { colorFilter } = filterItems;
    if (colorFilter.includes(color.id.toString())) {
      filterItems.colorFilter = colorFilter.filter((c) => c !== color.id.toString());
      setFilterItems({ ...filterItems });
    } else {
      colorFilter.push(color.id.toString());
      filterItems.colorFilter = colorFilter;
      setFilterItems({ ...filterItems });
    }
    setColorCounter(filterItems.colorFilter.length);
  };

  useEffect(() => {
    setColorCounter(filterItems.colorFilter.length);
  }, [filterItems.colorFilter.length]);

  const handlePatternChange = (pattern) => {
    const { patternFilter } = filterItems;
    if (patternFilter.includes(pattern.id.toString())) {
      filterItems.patternFilter = patternFilter.filter((c) => c !== pattern.id.toString());
      setFilterItems({ ...filterItems });
    } else {
      patternFilter.push(pattern.id.toString());
      filterItems.patternFilter = patternFilter;
      setFilterItems({ ...filterItems });
    }
    setPatternCounter(filterItems.patternFilter.length);
  };

  function highlightColor(id) {
    return filterItems.colorFilter.includes(id.toString());
  }

  function highlightPattern(id) {
    return filterItems.patternFilter.includes(id.toString());
  }

  const gridStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #F4F2F3',
    padding: 15,
  };

  const buttonClassLeft = {
    display: 'inline-block',
    cursor: 'pointer',
    marginTop: 10,
    marginRight: 15,
  };

  const buttonClassRight = {
    display: 'inline-block',
    marginTop: 10,
    marginRight: 15,
    cursor: 'pointer',
  };

  const iconHighlightedStyle = {
    border: `2px solid ${selectColor}`,
    borderRadius: 17,
  };

  const iconNoHighlightedStyle = {
    border: 'none',
    borderRadius: 0,
  };

  return (
    <>
      {!isPortait ? (
        <Grid container style={gridStyle}>
          <Grid item xs={7}>
            <Typography variant="subtitle1">{t('color_text')}</Typography>

            {allScenes[selectedSceneIndex].active_colors.map((color) => {
              const availableColor = jsonData.colors.filter((item) => item.id === Number(color))[0];

              return availableColor ? (
                <div style={buttonClassLeft} key={availableColor.id}>
                  <Tooltip title={availableColor.post_title}>
                    <img
                      onClick={() => handleColorChange(availableColor)}
                      alt={availableColor.post_title}
                      src={colorIconArray[availableColor.id]}
                      width="23px"
                      role="presentation"
                      style={highlightColor(availableColor.id) ? iconHighlightedStyle : iconNoHighlightedStyle}
                    />
                  </Tooltip>
                </div>
              ) : (
                ''
              );
            })}
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('pattern_text')}</Typography>

            {allScenes[selectedSceneIndex].active_structures.map((pattern) => {
              const availablePattern = jsonData.patterns.filter((item) => item.id === Number(pattern))[0];

              return availablePattern ? (
                <div style={buttonClassRight} key={availablePattern.id}>
                  <Tooltip title={availablePattern.post_title}>
                    <img
                      onClick={() => handlePatternChange(availablePattern)}
                      alt={availablePattern.post_title}
                      src={availablePattern.image.url}
                      role="presentation"
                      width="23px"
                      style={highlightPattern(availablePattern.id) ? iconHighlightedStyle : iconNoHighlightedStyle}
                    />
                  </Tooltip>
                </div>
              ) : (
                ''
              );
            })}
          </Grid>
        </Grid>
      ) : (
        <div>
          <Accordion onChange={(event, expanded) => handlePatternExpanded(expanded)} expanded={isPatternExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className={classes.heading}>
                <Grid container>
                  <Grid item xs={11}>
                    {t('color_text')}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    ({colorCounter})
                  </Grid>
                </Grid>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item spacing={1}>
                <Grid item>
                  <Grid container>
                    {allScenes[selectedSceneIndex].active_colors.map((color) => {
                      const availableColor = jsonData.colors.filter((item) => item.id === Number(color))[0];

                      return availableColor ? (
                        <div style={buttonClassLeft} key={availableColor.id}>
                          <Tooltip title={availableColor.post_title}>
                            <img
                              onClick={() => handleColorChange(availableColor)}
                              alt={availableColor.post_title}
                              src={colorIconArray[availableColor.id]}
                              width="23px"
                              role="presentation"
                              style={highlightColor(availableColor.id) ? iconHighlightedStyle : iconNoHighlightedStyle}
                            />
                          </Tooltip>
                        </div>
                      ) : (
                        ''
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion onChange={(event, expanded) => handleOnChange(expanded)} expanded={isExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className={classes.heading}>
                <Grid container>
                  <Grid item xs={11}>
                    {t('pattern_text')}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    ({patternCounter})
                  </Grid>
                </Grid>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item spacing={1}>
                <Grid item>
                  <Grid container>
                    {allScenes[selectedSceneIndex].active_structures.map((pattern) => {
                      const availablePattern = jsonData.patterns.filter((item) => item.id === Number(pattern))[0];

                      return availablePattern ? (
                        <div style={buttonClassRight} key={availablePattern.id}>
                          <Tooltip title={availablePattern.post_title}>
                            <img
                              onClick={() => handlePatternChange(availablePattern)}
                              alt={availablePattern.post_title}
                              src={availablePattern.image.url}
                              role="presentation"
                              width="23px"
                              style={
                                highlightPattern(availablePattern.id) ? iconHighlightedStyle : iconNoHighlightedStyle
                              }
                            />
                          </Tooltip>
                        </div>
                      ) : (
                        ''
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
}
