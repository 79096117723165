import React from 'react';
import Card from '@material-ui/core/Card';
import { Button, CardContent, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import copyIcon from '../assets/copy/content_copy-24px.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    fontSize: '0.8em',
  },
  expand: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  cardHeaderStyles: {
    marginBottom: '0',
    padding: '1%',
    paddingBottom: '0',
    float: 'right',
  },
  cardStyles: {
    border: (param) => `1px solid ${param.borderColor}`,
  },
}));

export default function ClipboardContent({ copy, handleClick, embedCode, borderColor }) {
  const classes = useStyles({ borderColor });
  return (
    <div>
      <Card className={[classes.root, classes.cardStyles]} fullWidth variant="outlined">
        <CardHeader
          className={classes.cardHeaderStyles}
          action={
            <Button
              onMouseDown={() => copy(embedCode)}
              onClick={handleClick({
                vertical: 'top',
                horizontal: 'right',
              })}
              color="primary"
              style={{ float: 'right', marginBottom: '0' }}
            >
              <img src={copyIcon} alt="copy icon" />
            </Button>
          }
        />

        <CardContent style={{ padding: '1%' }}>
          <Typography variant="body2">{embedCode}</Typography>
        </CardContent>
      </Card>
    </div>
  );
}
