import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Container } from '@material-ui/core';
import FabricHorizontalPagination from './FabricHorizontalPagination';
import FabricDetailsDialog from './dialog/FabricDetailsDialog';
import { useData } from '../../DataProvider';
import { IsPhonePortrait, IsTabletLandScape, IsTabletPortrait } from '../../../util/util';

const tabHeight = '35px';
const useStyles = makeStyles({
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    textTransform: 'capitalize',
    width: '100%',
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  indicator: {
    backgroundColor: (props) => props.selectColor,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container style={{ padding: 0 }}>
          <Box p={3}>
            <Typography component="span" variant="body2">
              {children}
            </Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MobileMiniView({ hotspotIndex, hotspotId, index = 0, closeMiniView }) {
  const isTabletPortrait = IsTabletPortrait();
  const isTabletLandScape = IsTabletLandScape();
  const isPhoneLandScapeSmall = useMediaQuery({ minWidth: 700, maxWidth: 750, orientation: 'landscape' });
  const isPhoneLandScapeBig = useMediaQuery({ minWidth: 750, maxWidth: 950, orientation: 'landscape' });
  const isPhoneLandPortait = IsPhonePortrait();
  const { t } = useTranslation();

  let numberOfItems = 10;

  if (isTabletPortrait) {
    numberOfItems = 5;
  } else if (isTabletLandScape) {
    numberOfItems = 7;
  } else if (isPhoneLandScapeSmall) {
    numberOfItems = 4;
  } else if (isPhoneLandScapeBig) {
    numberOfItems = 5;
  } else if (isPhoneLandPortait) {
    numberOfItems = 3;
  }

  const { watchMap, selectColor } = useData();
  const classes = useStyles({ selectColor });
  const [value, setValue] = React.useState(index);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(tabIndex) {
    return {
      id: `simple-tab-${tabIndex}`,
      'aria-controls': `simple-tabpanel-${tabIndex}`,
    };
  }

  function showNumberOfItems() {
    if (watchMap.size > 0) return `(${watchMap.size})`;
    return '';
  }

  return (
    <>
      <Paper square>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator,
            }}
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="#efc743"
            textColor="#efc743"
            aria-label="icon tabs example"
          >
            <Tab
              classes={{
                root: classes.tabsRoot,
              }}
              label={t('overview')}
              {...a11yProps(0)}
            />
            <Tab
              classes={{
                root: classes.tabsRoot,
              }}
              label={`${t('watchlist')} ${showNumberOfItems()}`}
              aria-label="search"
              {...a11yProps(1)}
            />
            <Tab
              classes={{
                root: classes.tabsRoot,
              }}
              label={t('fabric_detail')}
              aria-label="search"
              {...a11yProps(2)}
            />
          </Tabs>
          <div>
            <IconButton aria-label="close" onClick={closeMiniView}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <TabPanel value={value} index={0}>
          <FabricHorizontalPagination hotspotIndex={hotspotIndex} hotspotId={hotspotId} todosPerPage={numberOfItems} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {watchMap.size > 0 ? (
            <FabricHorizontalPagination todosPerPage={numberOfItems} isWatchList />
          ) : (
            <div>{t('empty_watchlist_text')}</div>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FabricDetailsDialog closeMiniView={closeMiniView} onClose={() => {}} />
        </TabPanel>
      </Paper>
    </>
  );
}

MobileMiniView.propTypes = {
  index: PropTypes.number,
  closeMiniView: PropTypes.func.isRequired,
};

MobileMiniView.defaultProps = {
  index: 0,
};
