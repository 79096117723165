import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import PaginationCard from './PaginationCard';
import { useData } from '../DataProvider';
import { getProductGroupByOriginalId } from '../../util/util';

export default function FabricWatchList({
  numberOfElements,
  isDraggable = false,
  cardId = -1,
  isArticleSearch,
  isOnComparedScene,
  subgroupId,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [counter, setCounter] = useState(1);
  const [cardsDragState, setCardsDragState] = useState([]);
  const { watchMap, updateWatchMap, currentScene, jsonData } = useData();
  const indexOfLastTodo = currentPage * numberOfElements;
  const indexOfFirstTodo = indexOfLastTodo - numberOfElements;
  const newMap = useRef(new Map());
  const { t } = useTranslation();

  const currentElements = cardsDragState.slice(indexOfFirstTodo, indexOfLastTodo);

  const productGroup = getProductGroupByOriginalId(currentScene.product_group, jsonData);

  function insertAtIndex(index, key, value, map) {
    const arr = Array.from(map);
    arr.splice(index, 0, [key, value]);
    return new Map(arr);
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cardsDragState[dragIndex];
      setCardsDragState(
        update(cardsDragState, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );

      Object.keys(watchMap).forEach((k) => {
        if (k.indexOf(dragIndex) === 0) {
          delete watchMap[k];
        }
      });

      const newmap = insertAtIndex(hoverIndex, dragCard.id, dragCard, watchMap);
      updateWatchMap(newmap);
    },
    [cardsDragState, updateWatchMap, watchMap],
  );

  let renderDraggableCards;
  if (cardId === -1) {
    renderDraggableCards = currentElements.map((cardInfos, index) => (
      <Grid item key={cardInfos.id}>
        <PaginationCard
          key={cardInfos.id}
          cardInfos={cardInfos}
          id={cardInfos.id}
          index={index}
          moveCard={moveCard}
          isDraggable={isDraggable}
          isArticleSearch={isArticleSearch}
          isOnComparedScene={isOnComparedScene}
          isWatchlist
          subgroupId={subgroupId}
          isGardenFurniture={productGroup.isGardenFurniture === 'true'}
        />
      </Grid>
    ));
  } else {
    renderDraggableCards = currentElements
      .filter((el) => el.product.id === cardId)
      .map((cardInfos, index) => (
        <Grid item key={cardInfos.id}>
          <PaginationCard
            key={cardInfos.id}
            cardInfos={cardInfos}
            id={cardInfos.id}
            index={index}
            moveCard={moveCard}
            isDraggable={isDraggable}
            isArticleSearch={isArticleSearch}
            isWatchlist
            isGardenFurniture={productGroup.isGardenFurniture === 'true'}
          />
        </Grid>
      ));
  }

  const buttonStyles = {
    color: ' #414141',
    marginTop: 30,
    textTransform: 'lowercase',
  };

  const bottomNavBar = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  useEffect(() => {
    setCurrentPage(counter);
  }, [counter]);

  useEffect(() => {
    const tmpArray = [];
    watchMap.forEach((value, key) => {
      const tmpObject = value;
      tmpObject.id = key;
      tmpArray.push(tmpObject);
    });

    setCardsDragState(tmpArray);
  }, [watchMap]);

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (cardsDragState.length === 0) return;

    newMap.current = new Map();
    for (let index = 0; index < cardsDragState.length; index += 1) {
      const currentObj = cardsDragState[index];
      const currentElementId = currentObj.id;
      currentObj.dragIndex = index;
      newMap.current.set(currentElementId, currentObj);
    }

    updateWatchMap(newMap.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsDragState.length, isDraggable]);

  function next() {
    setCounter(counter + 1);
  }

  function previous() {
    setCounter(counter - 1);
  }

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {renderDraggableCards}
      </Grid>
      <div style={bottomNavBar}>
        <div>
          {indexOfFirstTodo > 0 ? (
            <Button onClick={previous} style={buttonStyles}>
              <ArrowBackIosIcon />
              {indexOfLastTodo >= cardsDragState.length ? cardsDragState.length : indexOfLastTodo} von{' '}
              {cardsDragState.length}
            </Button>
          ) : null}
        </div>
        <div>
          {indexOfLastTodo < cardsDragState.length ? (
            <Button onClick={next} style={buttonStyles}>
              {t('next_btn')}
              <ArrowForwardIosIcon />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

FabricWatchList.propTypes = {
  isDraggable: PropTypes.bool.isRequired,
  cardId: PropTypes.number,
  isOnComparedScene: PropTypes.bool,
};

FabricWatchList.defaultProps = {
  cardId: -1,
  isOnComparedScene: false,
};
