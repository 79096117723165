import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import FabricItemInfoTab from '../../../WatchListDialog/FabricItemInfoTab';
import { useData } from '../../../DataProvider';
import FabricItemsDetailsTab from '../../../WatchListDialog/FabricItemsDetailsTab';
import FabricItemCombinationTab from '../../../WatchListDialog/FabricItemCombinationTab';
import CustomerInquiryDialog from '../../../FabricDialog/CustomerInquiryDialog';
import api from '../../../../api/api';
import { getColorById, getProductGroupByOriginalId, getSelectedItemById } from '../../../../util/util';

const tabHeight = '35px';
const useStyles = makeStyles({
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    textTransform: 'capitalize',
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  indicator: {
    backgroundColor: (props) => props.selectColor,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FabricDetailsTabPane({ index = 0, isArticleSearch, direction }) {
  const [value, setValue] = useState(index);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const {
    selectedItemsPerIndex,
    selectedItemsPerIndexRight,
    selectedSceneIndex,
    selectedSearchItems,
    selectColor,
    allScenes,
    userId,
    setId,
    jsonData,
    currentScene,
  } = useData();
  const classes = useStyles({ selectColor });
  let selectedFabricItems = {};

  if (isArticleSearch) {
    selectedFabricItems = { ...selectedSearchItems };
  } else if (direction === 'right') {
    selectedFabricItems = { ...selectedItemsPerIndexRight };
  } else {
    selectedFabricItems = { ...selectedItemsPerIndex };
  }

  const getCurrentSelectedItemsPerIndex = () => {
    const numberOfHotspots = getNumberOfHotspots();
    const currentSelectedItems = Object.values(selectedFabricItems)
      .map((elem, i) => (numberOfHotspots > i ? elem : ''))
      .filter((elem) => elem !== '');

    return currentSelectedItems;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getNumberOfHotspots = () => {
    const currentGroups = allScenes[selectedSceneIndex].scene.hotspots
      .map((elem) => elem.product_group)
      .filter((elem) => elem !== '7318');
    return currentGroups.length;
  };

  const downloadPDF = async () => {
    setLoading(true);
    const currentSelectedItems = getCurrentSelectedItemsPerIndex();

    const pdfParams = {
      isWatchList: false,
      userId,
      fabricIds: currentSelectedItems.map((elem) => elem.fabric.id),
      setId,
      hs1: currentSelectedItems[0] ? currentSelectedItems[0].fabric.post_title : null,
      hs2: currentSelectedItems[1] ? currentSelectedItems[1].fabric.post_title : null,
      hs3: currentSelectedItems[2] ? currentSelectedItems[2].fabric.post_title : null,
      folder_name: allScenes[selectedSceneIndex].scene.sub_path,
      scene_name: allScenes[selectedSceneIndex].scene.post_title,
      product_subgroup_id_1: currentSelectedItems[0] ? currentSelectedItems[0].subgroupId : null,
      product_subgroup_id_2: currentSelectedItems[1] ? currentSelectedItems[1].subgroupId : null,
      product_subgroup_id_3: currentSelectedItems[2] ? currentSelectedItems[2].subgroupId : null,
      shadow_color: getColorById(currentSelectedItems[0].fabric.color_primary, jsonData).post_title,
      lang: new URLSearchParams(window.location.search).get('lang') || localStorage.getItem('i18nextLng'),
    };

    const pdf = await api.generatePDF(pdfParams);
    const url = URL.createObjectURL(pdf.data);
    setLoading(false);

    window.open(url);
  };

  const numberOfSceneItems = [];
  allScenes[selectedSceneIndex].scene.hotspots.map((hotspot) => {
    if (hotspot.product_group !== '7318') {
      numberOfSceneItems.push(hotspot);
    }
    return null;
  });

  function tabsProperties(tabIndex) {
    return {
      id: `simple-tab-${tabIndex}`,
      'aria-controls': `simple-tabpanel-${tabIndex}`,
    };
  }

  function FabricItemsDetailView() {
    const productGroup = getProductGroupByOriginalId(currentScene.product_group, jsonData);

    return getCurrentSelectedItemsPerIndex().map((elem, i) => {
      if (numberOfSceneItems.length <= i) {
        return null;
      }
      return (
        <FabricItemInfoTab
          key={elem.fabric.id}
          cardItem={elem.fabric}
          hotspotId={elem.subgroupId}
          isGardenFurniture={productGroup.isGardenFurniture === 'true'}
        />
      );
    });
  }

  function CardTypeItemsB() {
    const productGroup = getProductGroupByOriginalId(currentScene.product_group, jsonData);

    if (isArticleSearch) {
      let productGroupId = allScenes[selectedSceneIndex].scene.hotspots[0].product_group;
      if (productGroupId === '7318') productGroupId = allScenes[selectedSceneIndex].scene.hotspots[1].product_group;

      const elem = getSelectedItemById(selectedFabricItems, productGroupId);

      return (
        <FabricItemsDetailsTab
          key={elem.fabric.id}
          cardItem={elem}
          isArticleSearch={isArticleSearch}
          hotspotId={elem.subgroupId}
          isGardenFurniture={productGroup.isGardenFurniture === 'true'}
        />
      );
    }

    return getCurrentSelectedItemsPerIndex().map((elem, i) => {
      if (numberOfSceneItems.length <= i) {
        return null;
      }
      return (
        <FabricItemsDetailsTab
          key={elem.fabric.id}
          cardItem={elem}
          isArticleSearch={isArticleSearch}
          hotspotId={elem.subgroupId}
          isGardenFurniture={productGroup.isGardenFurniture === 'true'}
        />
      );
    });
  }

  function CardTypeItemsC() {
    const currentSelectedItems = getCurrentSelectedItemsPerIndex();

    return currentSelectedItems.map((elem, i) => {
      if (numberOfSceneItems.length <= i) {
        return null;
      }
      return (
        <Grid key={elem.fabric.id} item xs={12 / getNumberOfHotspots()}>
          <FabricItemCombinationTab cardItem={elem} />
        </Grid>
      );
    });
  }

  return (
    <Paper square>
      <div>
        <Tabs
          inkBarStyle={{ background: selectColor }}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.indicator,
          }}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="#efc743"
          textColor="#efc743"
          aria-label="icon tabs example"
        >
          <Tab
            classes={{
              root: classes.tabsRoot,
            }}
            label={t('detail_view')}
            {...tabsProperties(0)}
          />
          <Tab
            classes={{
              root: classes.tabsRoot,
            }}
            label={t('fabric_info')}
            aria-label="search"
            {...tabsProperties(1)}
          />
          <Tab
            classes={{
              root: classes.tabsRoot,
            }}
            label={t('fabric_combination')}
            aria-label="favorite"
            {...tabsProperties(2)}
          />
        </Tabs>
      </div>
      <TabPanel value={value} index={0} style={{ textAlign: 'right' }}>
        <CardTypeItemsB />
        <Button style={{ textTransform: 'capitalize' }} type="button" onClick={downloadPDF}>
          PDF-Download
        </Button>
      </TabPanel>
      <TabPanel value={value} index={1} style={{ textAlign: 'right' }}>
        <FabricItemsDetailView />
        <Button style={{ textTransform: 'capitalize' }} type="button" onClick={downloadPDF}>
          PDF-Download
        </Button>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container>
          <CardTypeItemsC />
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button style={{ textTransform: 'capitalize' }} type="button" onClick={downloadPDF}>
            PDF-Download
          </Button>
          <Button type="button" onClick={() => {}}>
            <CustomerInquiryDialog margin="false" />
          </Button>
        </div>
      </TabPanel>
      {loading && (
        <div>
          <CircularProgress style={{ margin: '0 auto', display: 'flex' }} />
          <br />
        </div>
      )}
    </Paper>
  );
}
