import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Button, Container, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RWebShare } from 'react-web-share';
import InputFieldWithCopyButton from './InputFieldWithCopyButton';
import { useData } from '../DataProvider';

const tabHeight = '35px';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    textTransform: 'capitalize',
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  indicator: {
    backgroundColor: (props) => props.selectColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ShareTabPane({ localCardId, cardItem }) {
  const { selectColor } = useData();
  const classes = useStyles({ selectColor });
  const { t } = useTranslation();
  const [link, setLink] = useState(false);

  const messageText = t('found_fabric_text').replace('<0>fabric</0>', cardItem.post_title);
  const subjectText = t('recommendation_of_fabric');

  const [message, setMessage] = useState(messageText);
  const [subject, setSubject] = useState(subjectText);

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container justify="space-between">
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label={t('subject')}
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className={classes.textField}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label={t('message')}
            variant="outlined"
            multiline
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.fontSize,
              },
            }}
          />
          <InputFieldWithCopyButton setLink={setLink} localCardId={localCardId} />
          {/* <FormControlLabel
            style={{ margin: 30 }}
            control={
              <Checkbox
                fullWidth
                checked={checkboxState}
                onChange={(event) => setCheckboxState(event.target.checked)}
                name="checkedB"
                color={error && checkboxState === false ? 'default' : 'primary'}
                required
              />
            }
            label={
              <Trans
                i18nKey="data_privacy"
                t={t}
                style={error && checkboxState === false ? { color: 'red' } : {}}
                components={[
                  <a href={`${process.env.REACT_APP_BASE_URL}datenschutzerklaerung?lang=${language}`}>
                    {t('data_privacy')}
                  </a>,
                ]}
              />
            }
          /> */}
        </Grid>
        <Grid xs={12} md={12} style={{ textAlign: 'right' }}>
          <RWebShare
            data={{
              text: message,
              title: subject,
              url: link,
            }}
          >
            <Button variant="contained" color="secondary">
              {t('share')}
            </Button>
          </RWebShare>
        </Grid>
      </Grid>
    </form>
  );
}
