import * as React from 'react';
import { useRef, useState, useEffect, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import ContainerDimensions from 'react-container-dimensions';
import { Container } from '@material-ui/core';
import FabricTabPane from './FabricTabPane';
import {
  getTriggerCloseDialog,
  getTriggerOpenDialog,
  openFabricViewDialog,
  triggerCloseMiniView,
} from '../Subjects/subjects';
import {
  getPictogramSrcByProductSubgroupId,
  IsPhoneLandScape,
  IsPhonePortrait,
  pickTextColorBasedOnBgColorAdvanced,
} from '../../util/util';
import { useData } from '../DataProvider';

export default function FabricViewDialog({
  left,
  top,
  isSpecial,
  hotspotId,
  hotspotIndex,
  orientation,
  isCompareMode,
  productGroupId,
  hotspots,
}) {
  const childRef = useRef();
  const { jsonData, selectColor, selectedItemsPerIndex, isFullScreen, openFabricMode } = useData();
  const isPhonePortrait = IsPhonePortrait();
  const isPhoneLandScape = IsPhoneLandScape();
  const [open, setOpen] = useState(false);
  const [isHovering, setHovering] = useState(false);
  const [isArticleSearch, setIsArticleSearch] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [tabIndex, setTabIndex] = useState(0);
  const [currentHotspotId, setCurrentHotspotId] = useState(hotspotId);
  const [currentHotspotIndex, setCurrentHotspotIndex] = useState(hotspotIndex);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [invertColor, setInvertColor] = useState(selectColor);
  const maxWidth = 1232;

  const { t } = useTranslation();
  const calculatePositions = useCallback(() => {
    let posX;
    let posY;
    const { innerWidth: width, innerHeight } = window;

    let height = 700;

    if (width < 699) {
      height = 300;
    }
    if (width < 900 && innerHeight < 699) {
      height = 344;
    }

    let windowWidth = width > maxWidth ? maxWidth : width;
    if (isFullScreen) {
      height = 880;
      windowWidth = width;
    }

    if (width > innerHeight && width < 1250 && width > 1000) {
      windowWidth -= 100;
    }
    const actualWidth = (height * 16) / 9;
    const offset = (actualWidth - windowWidth) / 2;

    posX = actualWidth * left - offset;

    posY = height * top;

    if (width > 750) {
      posY -= 25;
      posX -= 25;
    }

    if (isFullScreen) {
      posY += 25;
      posX += 25;
    }

    if (posX < 0) {
      // posX = -2000;
    }

    if (posY < 0) {
      // posY = -2000;
    }

    return { posX, posY };
  }, [isFullScreen, left, top]);

  const handleClickOpen = () => {
    setOpen(true);
    triggerCloseMiniView();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectColor) {
      const darkOrWhite = pickTextColorBasedOnBgColorAdvanced(selectColor, '#FFFFFF', '#000000');
      setInvertColor(darkOrWhite);
    }
    // setCurrentHotspotId(hotspotId);
  }, [hotspotId, selectColor]);

  useEffect(() => {
    function handleResize() {
      const positions = calculatePositions(true);
      setPositionX(positions.posX);
      setPositionY(positions.posY);
    }

    const positions = calculatePositions();
    setPositionX(positions.posX);
    setPositionY(positions.posY);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculatePositions, isFullScreen, positionX]);

  getTriggerOpenDialog().subscribe((item) => {
    const { index: element } = item;
    if (isSpecial) {
      if (item.isArticleSearch) setIsArticleSearch(true);

      setTabIndex(element.index);
      if (item) setTabIndex(element.index);
      if (item) setCurrentHotspotId(element.hotspotId);
      if (item) setCurrentHotspotIndex(element.hotspotIndex);
      handleClickOpen();
    }
  });

  useEffect(() => {
    if (openFabricMode) {
      if (hotspots) {
        if (openFabricMode === 'search') {
          openFabricViewDialog({ index: 1 }, false);
        } else {
          const hotspotNumber = openFabricMode.replace(/^\D+/g, '') - 1;
          if (hotspots.filter((hs) => hs.product_group !== '7318').length > hotspotNumber) {
            const openHotspotId = hotspots.filter((hs) => hs.product_group !== '7318')[hotspotNumber].product_group;
            const paramsObject = { index: 0, hotspotId: openHotspotId, hotspotIndex: hotspotNumber };

            if (openHotspotId) openFabricViewDialog(paramsObject, false);
          }
        }
      }
    }
  }, [hotspotId, hotspots, openFabricMode]);

  getTriggerCloseDialog().subscribe(() => {
    handleClose();
  });

  const buttonStyleLeft = {
    position: 'absolute',
    left: '-120px',
    background: `0% 0% no-repeat padding-box padding-box ${selectColor}`,
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
    textAlign: 'left',
    paddingLeft: '8px',
    height: '40px',
    width: '160px',
    fontSize: '11px',
    minWidth: 50,
  };

  const buttonLabelStyle = {
    position: 'absolute',
    left: positionX || 0,
    top: positionY || 0,
    width: 200,
    zIndex: 1,
  };

  const buttonStyleRight = {
    position: 'absolute',
    background: backgroundColor,
    width: '80%',
    borderTopLeftRadius: '30px',
    borderBottomLeftRadius: '30px',
    justifyContent: 'space-between',
    textAlign: 'right',
    fontSize: '11px',
    minWidth: 50,
    zIndex: -1,
    height: 40,
    paddingRight: '8px',
    top: 0,
  };

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  const handleMouseEnter = () => {
    setBackgroundColor(`${selectColor} 0% 0% no-repeat padding-box`);
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setBackgroundColor('transparent');
    setHovering(false);
  };

  const pictoGramSrc =
    currentHotspotId && hotspotId !== '0' ? getPictogramSrcByProductSubgroupId(productGroupId, jsonData) : '';

  const fabric =
    !isSpecial && hotspotId !== '0' && selectedItemsPerIndex[currentHotspotIndex]
      ? selectedItemsPerIndex[currentHotspotIndex].fabric
      : '';

  const fabricNumber =
    fabric.customer_set_alt_article_number && fabric.customer_set_alt_article_number.length > 3
      ? fabric.customer_set_alt_article_number
      : fabric.post_title;

  const concatFabricNumber =
    fabricNumber && fabricNumber.length === 6
      ? `${fabricNumber.substring(0, 3)} ${fabricNumber.substring(3, 6)}`
      : fabricNumber;

  return (
    <>
      {!isSpecial ? (
        <div>
          <div
            ref={childRef}
            id="hotspot_button_1"
            className="hotspot"
            style={buttonLabelStyle}
            onMouseLeave={handleMouseLeave}
          >
            {orientation === 'left' && isHovering && (
              <div style={{ position: 'relative' }}>
                <div style={buttonStyleLeft}>
                  <b style={{ color: invertColor }}>{t('select_fabric')}</b>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <img
                      src={pictoGramSrc}
                      alt="Pictogram"
                      style={{ filter: `invert(${invertColor === '#FFFFFF' ? 1 : 0})`, width: 25, marginRight: 5 }}
                    />
                    <div style={{ color: invertColor }}>{concatFabricNumber}</div>
                  </div>
                </div>
              </div>
            )}
            <div className="button-wrap">
              <button
                type="button"
                className="pulse-button"
                style={{ background: selectColor, color: 'white' }}
                onClick={handleClickOpen}
                onMouseEnter={handleMouseEnter}
              >
                +
              </button>
            </div>
            {orientation === 'right' && isHovering && (
              <div style={buttonStyleRight}>
                <b style={{ color: invertColor }}>{t('select_fabric')}</b>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <img
                    src={pictoGramSrc}
                    alt="Pictogram"
                    style={{ filter: `invert(${invertColor === '#FFFFFF' ? 1 : 0})`, width: 25, marginRight: 5 }}
                  />
                  <div style={{ color: invertColor }}>{concatFabricNumber}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {open && (
        <Dialog
          maxWidth="md"
          fullScreen={isPhonePortrait || isPhoneLandScape}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle style={{ textTransform: 'uppercase' }} id="max-width-dialog-title">
            {t('select_fabric')}
          </DialogTitle>
          <DialogContent>
            <Container>
              <ContainerDimensions>
                {({ width, height }) => (
                  <FabricTabPane
                    index={tabIndex}
                    hotspotId={currentHotspotId}
                    hotspotIndex={currentHotspotIndex}
                    isArticleSearch={isArticleSearch}
                    isCompareMode={isCompareMode}
                    productGroupId={productGroupId}
                    containerWidth={width}
                    containerHeight={height}
                  />
                )}
              </ContainerDimensions>
            </Container>
          </DialogContent>
          <DialogActions>
            <IconButton aria-label="close" style={closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

FabricViewDialog.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  isSpecial: PropTypes.bool,
  hotspotId: PropTypes.string,
  hotspotIndex: PropTypes.number,
  orientation: PropTypes.string,
  isCompareMode: PropTypes.bool,
};

FabricViewDialog.defaultProps = {
  top: 0,
  left: 0,
  isSpecial: false,
  hotspotId: '0',
  hotspotIndex: 0,
  orientation: 'left',
  isCompareMode: false,
};
