import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as PropTypes from 'prop-types';
import CardActionArea from '@material-ui/core/CardActionArea';

import { useData } from '../DataProvider';
import { replaceSpecialChars } from '../../util/util';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: '20vw',
  },
  media: {
    height: 140,
  },
}));

export default function FabricItemInfoTab({ cardItem, hotspotId }) {
  const classes = useStyles();

  const fabricNameItem = replaceSpecialChars(cardItem.fabric.post_title);
  const src = `${process.env.REACT_APP_BASE_URL}/wp-content/uploads/stoffdetailansicht/${fabricNameItem}/hd.jpg`;

  const { selectColor, watchMap, updateCard } = useData();
  const isOnWatchMap = !!watchMap.get(cardItem.fabric.id);

  const [isFavorite, setFavorite] = useState(isOnWatchMap);

  const setItemFavorite = () => {
    setFavorite(!isFavorite);

    const newCardInfo = {
      subgroupId: hotspotId,
      isFavorite: !isFavorite,
      product: cardItem.fabric,
    };
    updateCard(cardItem.fabric.id, newCardInfo);
  };

  const fabricNumber =
    cardItem.fabric.customer_set_alt_article_number && cardItem.fabric.customer_set_alt_article_number.length > 3
      ? cardItem.fabric.customer_set_alt_article_number
      : cardItem.fabric.post_title;

  const concatFabricNumber =
    fabricNumber.length === 6 ? `${fabricNumber.substring(0, 3)} ${fabricNumber.substring(3, 6)}` : fabricNumber;

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography component="h6" variant="h6">
            {cardItem.fabric.title}
          </Typography>
          <Typography variant="h6" style={{ fontSize: '1rem' }}>
            <b> {concatFabricNumber}</b>
          </Typography>
        </CardContent>
        <CardMedia className={classes.media} image={src} title="stoff" />
      </CardActionArea>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton aria-label="like" style={{ color: selectColor }} onClick={setItemFavorite}>
          {isFavorite ? (
            <FavoriteIcon style={{ color: selectColor }} />
          ) : (
            <FavoriteBorderIcon style={{ color: selectColor }} />
          )}
        </IconButton>
      </div>
    </Card>
  );
}

FabricItemInfoTab.propTypes = {
  cardItem: PropTypes.instanceOf(Object).isRequired,
};
