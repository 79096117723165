import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },

  navLink: {
    color: '#414141',
    textDecoration: 'none',
    fontSize: '20px',
  },
  bgHeader: {
    background: '#F8F8F8',
  },
  activeLink: {
    fontWeight: 600,
  },
}));

export default function LoggedInNavigationBar() {
  const { t } = useTranslation();
  const { queryParams } = useData();

  const { search } = useLocation();
  const loggedInUserId = new URLSearchParams(search).get('loggedInUserId');

  const classes = useStyles();
  return (
    <Grid container direction="row" justify="flex-end" alignItems="flex-start" className={classes.bgHeader}>
      <Grid item xs={6}>
        <Box p={1}>
          <NavLink className={classes.navLink} to={`/${queryParams}&loggedInUserId=${loggedInUserId}`}>
            DESIGN SELECTOR
          </NavLink>
        </Box>
      </Grid>
      <Grid item xs={3} justify="flex-end">
        <Box bgcolor="primary.box" p={1} style={{ textAlign: 'right' }}>
          <NavLink
            activeClassName={classes.activeLink}
            className={classes.navLink}
            to={`/embed${queryParams}&loggedInUserId=${loggedInUserId}`}
          >
            {t('Embedoptions')}
          </NavLink>
        </Box>
      </Grid>
      <Grid item xs={3} justify="flex-end" alignContent="flex-end">
        <Box bgcolor="primary.box" p={1} style={{ textAlign: 'right' }}>
          <NavLink
            activeClassName={classes.activeLink}
            className={classes.navLink}
            to={`/profile${queryParams}&loggedInUserId=${loggedInUserId}`}
          >
            {t('profile_data')}
          </NavLink>
        </Box>
      </Grid>
    </Grid>
  );
}
