import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PictoDach from '../assets/pictogram/Pikto_Dach.png';
import PictoMarkise from '../assets/pictogram/Pikto_Markise.png';
import PictoSchirm from '../assets/pictogram/Pikto_Schirm.png';
import PictoScreen from '../assets/pictogram/Pikto_Screen.png';
import PictoSeite from '../assets/pictogram/Pikto_Seite.png';
import PictoSenkrecht from '../assets/pictogram/Pikto_Senkrecht.png';
import PictoVolant from '../assets/pictogram/Pikto_Volant_absenkbar.png';
import PictoVorhang from '../assets/pictogram/Pikto_Vorhang.png';
import PictoKissen from '../assets/pictogram/Pikto Kissen.png';
import PictoGartenMoebel from '../assets/pictogram/Pikto Gartenmoebel.png';
import PictoPergolaVorne from '../assets/pictogram/Pikto Pergola Vorne.png';

export const minWidthDesktop = 1250;
export const minWidthTablet = 768;
export const maxWidthTablet = minWidthDesktop;
export const maxWidthPhone = minWidthTablet;

export const checkMobile = window.matchMedia('screen and (max-width: 575px)').matches;
export const checkTablet = window.matchMedia('screen and (min-width: 576px) and (max-width: 991px)').matches;
export const checkDesktop = window.matchMedia('screen and (min-width: 992px)').matches;

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function deepCompareObjects(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function getProductSubgroupById(productSubGroupId, data) {
  return data.product_subgroups.find((item) => item.id.toString() === productSubGroupId);
}

export function getProductGroupById(id, data) {
  return data.product_groups.find((item) => item.id.toString() === id);
}

export function getProductGroupByOriginalId(id, data) {
  return data.product_groups.find((item) => item.originalId.toString() === id);
}

export function getProductGroupByName(name, data) {
  return data.product_groups.find((item) => item.post_title === name);
}

export function getProductGroupsByContainingName(name, data) {
  return data.product_groups.filter((item) => item.post_title.toLowerCase().includes(name));
}

export function getProductSubgroupByName(name, data) {
  return data.product_subgroups.find((item) => item.subline_2 === name);
}

export function getCollectionById(collectionId, data) {
  return data.collections.find((item) => item.id.toString() === collectionId);
}

export function getPatternById(patternId, data) {
  return data.patterns.find((item) => item.id.toString() === patternId);
}

export function getColorById(colorId, data) {
  return data.colors.find((item) => item.id.toString() === colorId);
}

export function getDesignlineById(designId, data) {
  return data.design_lines.find((item) => item.id.toString() === designId);
}

export function getMaterialById(materialId, data) {
  return data.materials.find((item) => item.id.toString() === materialId);
}

export function getAttributeById(attributeId, data) {
  return data.attributes.find((item) => item.id.toString() === attributeId);
}

export function getSelectedItemById(selectedItems, hotspotSubGroupId) {
  const fabric = Object.values(selectedItems).filter((item) => item.subgroupId === hotspotSubGroupId)[0];
  if (fabric) {
    return fabric;
  }
  return Object.values(selectedItems)[0];
}

export function getSceneTypeIdFromSceneId(sceneId, data) {
  const found = data.scenes.find((el) => el.scene.id === sceneId);
  if (found) return found.scene_type_id;
  return -1;
}

export function getHotspotIndex(hotspot) {
  if (hotspot === undefined) return 0;
  if (hotspot.subfolder === 'HS1') return 0;
  if (hotspot.subfolder === 'HS2') return 1;
  if (hotspot.subfolder === 'HS3') return 2;
  return 0;
}

export function getHotspotIndexBySubgroupId(id, scene) {
  if (scene === undefined) return 0;
  const hotspot = scene.scene.hotspots.find((el) => el.product_group === id);
  return getHotspotIndex(hotspot);
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // window.addEventListener('resize', handleResize);

    // handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isPageInIFrame = () => window.location !== window.parent.location;

export const hasKeyInLocalStorage = (key) => localStorage.getItem(key) !== null;

export const getPictogramSrcByProductSubgroupId = (id, data) => {
  if (data === undefined) return '';
  const productSubgroup = getProductSubgroupById(id, data);
  if (productSubgroup) {
    const { subline_2: subline2 } = productSubgroup;
    if (subline2 === 'Markisenstoff') return PictoMarkise;
    if (subline2 === 'Volant') return PictoVolant;
    if (subline2 === 'Dachstoff') return PictoDach;
    if (subline2 === 'Seitenstoff') return PictoSeite;
    if (subline2 === 'Vorhangstoff') return PictoVorhang;
    if (subline2 === 'Schirmstoff') return PictoSchirm;
    if (subline2 === 'ZIP Screenstoff') return PictoScreen;
    if (subline2 === 'Senkrecht') return PictoSenkrecht;
    if (subline2 === 'Sonnensegelstoff') return PictoSenkrecht;
    if (subline2 === 'Gartenmoebelstoff') return PictoGartenMoebel;
    if (subline2 === 'Kissenstoff') return PictoKissen;
    if (subline2 === 'Vorne') return PictoPergolaVorne;
  }
  return PictoMarkise;
};

export const IsPhonePortrait = () => {
  const size = useMediaQuery({ maxWidth: 700, orientation: 'portrait' });
  return size;
};

export const IsPhoneLandScape = () => {
  const size = useMediaQuery({ maxWidth: 950, orientation: 'landscape' });
  return size;
};

export const IsTabletPortrait = () => {
  const size = useMediaQuery({ minWidth: 700, maxWidth: 1250, orientation: 'portrait' });
  return size;
};

export const IsTabletLandScape = () => {
  const size = useMediaQuery({ minWidth: 1000, maxWidth: 1250, orientation: 'landscape' });
  return size;
};

export const pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor, darkColor) => {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export const replaceSpecialChars = (element) => element.replace(/[`~!@#$%^&*()|+\-=?;:'",.<>{}[\]\\/]/gi, '-');

export const isCBA = (item) => ['1302', '7385', '6263'].includes(item);
export const isUV = (item) => ['1301', '1302', '7385', '6263', '7471', '7384'].includes(item);
export const isAllWeather = (item) => ['7385'].includes(item);
