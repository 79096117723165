import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './pages/App';
import theme from './theme';
import DataProvider from './components/DataProvider';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <DataProvider>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </DataProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
