import React, { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import '../../carousel.css';

import * as PropTypes from 'prop-types';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useMediaQuery } from 'react-responsive';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import FooterCarousel from './FooterCarousel';
import FabricViewDialog from '../FabricDialog/FabricViewDialog';
import WallViewDialog from '../FabricDialog/WallViewDialog';
import MiniView from './MiniView/MiniView';
import PreviewButtons from './PreviewButtons';
import Thumbs from './Thumbs/Thumbs';
import { useData } from '../DataProvider';
import { getHotspotIndex } from '../../util/util';
import { getTriggerCloseMiniView, getTriggerOpenMiniView } from '../Subjects/subjects';
import FullScreenButton from '../FabricDialog/FullScreenButton';
import LayeringComponent from '../LayeringComponent';
import MobileMiniView from './MiniView/MobileMiniView';

const useStyles = makeStyles({
  select: {
    '&:before': {
      // normal
      borderBottom: (props) => `2px solid ${props.color}`,
    },
    '&:after': {
      // focused
      borderBottom: (props) => `2px solid ${props.color}`,
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: (props) => `2px solid ${props.color}`,
    },
  },
});

export default function CarouselComponent({
  openMiniView,
  closeMiniView,
  toggleThumbView,
  closeThumbs,
  miniViewHotspotId,
  miniViewHotspotIndex,
  showMiniView,
  showThumbs,
}) {
  const {
    isFullScreen,
    selectedSceneIndex,
    setSelectedSceneIndex,
    selectedItems,
    jsonData,
    wallColor,
    selectColor,
    allScenes,
    setSelectedSceneId,
    setCurrentScene,
    selectedItemsPerIndex,
  } = useData();

  const classes = useStyles({ color: selectColor });
  const unique = [
    ...new Set(jsonData.scenes.sort((a, b) => a.menu_order - b.menu_order).map((item) => item.scene_type_name)),
  ];

  const screenHeight = window.innerHeight - 74;
  const [sceneName, setSceneName] = useState('');
  const imageRef = useRef();
  const isPhone = useMediaQuery({ maxWidth: 699, orientation: 'portrait' });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1224px)' });
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isTabletPortrait = useMediaQuery({ minWidth: 700, maxWidth: 1250, orientation: 'portrait' });

  let imageHeight = 700;
  const targetRef = useRef();

  if (isPhone && isPortrait) {
    imageHeight = 360;
  } else if (isTabletPortrait) {
    imageHeight = screenHeight > 700 ? 700 : screenHeight;
  }

  const navigate = useNavigate();

  const largeIconStyles = {
    width: 35,
    height: 35,
  };

  const croppedStyles = {
    width: '100%',
    height: imageHeight,
    objectFit: 'cover',
  };

  const fullScreenStyles = {
    position: 'absolute',
    height: window.innerHeight,
    objectFit: 'cover',
    width: '100%',
  };

  const layeringStyle = {
    position: 'absolute',
    height: imageHeight,
    ...croppedStyles,
  };

  FooterCarousel.propTypes = {
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      width: PropTypes.string,
      position: PropTypes.string,
    }),
  };

  const button = {
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    overflow: 'hidden',
    outline: 'none',
    color: '#4b4b4b',
    fontSize: 36,
  };

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    // eslint-disable-next-line no-nested-ternary
    bottom: isFullScreen ? 40 : isPortrait ? -10 : 25,
    width: 30,
    height: isFullScreen ? 32 : 48,
    cursor: 'pointer',
    ...button,
  };

  const indicatorStyles = {
    background: '#fff',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
    cursor: 'pointer',
  };

  const navContainerStyles = {
    position: 'absolute',
    bottom: isPortrait ? 0 : 34,
    left: 0,
    width: '100%',
  };

  const miniViewStyles = {
    position: 'absolute',
    bottom: 100,
    left: 0,
    width: '100%',
    backgroundColor: 'transparent',
    opacity: 1,
    height: 100,
    zIndex: 100,
  };

  const thumbsStyles = {
    position: 'absolute',
    bottom: isPhone ? 160 : 220,
    left: 0,
    width: '100%',
    backgroundColor: 'transparent',
    opacity: 1,
    height: 100,
    zIndex: 999,
  };

  const imageButtonStylesLow = {
    position: 'absolute',
    bottom: isPhone && isLandscape ? 80 : 60,
    left: 0,
    width: '100%',
    backgroundColor: 'transparent',
    opacity: 1,
    height: '18px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'noWrap',
  };

  const imageButtonStylesHigh = {
    ...imageButtonStylesLow,
    bottom: isPhone && isLandscape ? 240 : 220,
  };

  const navStyles = {
    backgroundColor: '#e2e6da',
    opacity: 0.5,
    height: 36,
    position: 'absolute',
    bottom: '2px',
    width: '100%',
  };

  const selectStyles = {
    position: 'absolute',
    bottom: 80,
    left: 10,
    background: selectColor,
    zIndex: 100,
    marginLeft: 2,
    borderRadius: 4,
  };

  getTriggerOpenMiniView().subscribe((items) => {
    openMiniView(items.index, items.productGroup);
  });

  getTriggerCloseMiniView().subscribe(() => {
    closeMiniView();
  });

  const handleChange = (event) => {
    setSceneName(event.target.value);
    const scene = jsonData.scenes.find((el) => el.scene_type_name === event.target.value);
    if (scene) {
      const urlParameters = window.location.search;
      if (urlParameters.includes('sceneTypeId')) {
        const newUrl = urlParameters.replace(/(sceneTypeId=)[0-9]*/, `$1${scene.scene_type_id}`);
        navigate(newUrl);
      } else if (urlParameters === '') {
        const newUrl = `?sceneTypeId=${scene.scene_type_id}`;
        navigate(newUrl);
      } else {
        const newUrl = `${urlParameters}&sceneTypeId=${scene.scene_type_id}`;
        navigate(newUrl);
      }

      window.location.reload();
    }
  };

  const handleLeftArrowClicked = (onClickHandler) => {
    onClickHandler();
    const updatedIndex = selectedSceneIndex - 1;
    setSelectedSceneIndex(updatedIndex);
  };

  const handleRightArrowClicked = (onClickHandler) => {
    onClickHandler();
    const updatedIndex = selectedSceneIndex + 1;
    setSelectedSceneIndex(updatedIndex);
  };

  const renderImages = allScenes.map((sceneItem, index) => (
    <React.Fragment key={`${sceneItem.scene.id}`}>
      <LayeringComponent
        imageRef={imageRef}
        index={index}
        sceneItem={sceneItem}
        isFullScreen={isFullScreen}
        fullScreenStyles={fullScreenStyles}
        layeringStyle={layeringStyle}
        wallColor={wallColor}
        selectedItems={selectedItems}
        selectedItemsPerIndex={selectedItemsPerIndex}
        jsonData={jsonData}
      />
      {isLandscape && (
        <FooterCarousel showThumbs={toggleThumbView} openMiniView={openMiniView} selectedItems={selectedItems} />
      )}
      {sceneItem.scene.hotspots &&
        sceneItem.scene.hotspots.map((hotspot) =>
          hotspot.product_group === '7318' ? (
            <WallViewDialog
              hotspotIndex={hotspot}
              key={`wallViewDialog${hotspot.product_group}`}
              left={Number(hotspot.x)}
              top={Number(hotspot.y)}
              orientation={hotspot.orientation}
            />
          ) : (
            <FabricViewDialog
              hotspots={sceneItem.scene.hotspots}
              hotspotId={hotspot.product_group}
              hotspotIndex={getHotspotIndex(hotspot)}
              key={`fabricViewDialog${hotspot.product_group}`}
              left={Number(hotspot.x)}
              top={Number(hotspot.y)}
              orientation={hotspot.orientation}
              productGroupId={hotspot.product_group}
            />
          ),
        )}
      {unique && unique.length > 1 && (
        <FormControl style={selectStyles}>
          <Select
            className={classes.select}
            value={sceneName}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange}
            margin="none"
            style={{ marginLeft: 10 }}
          >
            {unique &&
              unique.length > 1 &&
              unique.map((scene) => (
                <MenuItem
                  key={scene}
                  value={scene}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = selectColor;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = 'white';
                  }}
                >
                  {scene}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {!isMobileScreen && <FullScreenButton />}
      <div style={navContainerStyles}>
        {isPortrait || showThumbs ? null : (
          <>
            <div style={showMiniView ? imageButtonStylesHigh : imageButtonStylesLow}>
              <PreviewButtons showMiniView={openMiniView} selectedItems={selectedItemsPerIndex} position="right" />
            </div>
          </>
        )}

        {showMiniView && !isPhone && isLandscape ? (
          <div style={miniViewStyles}>
            <MiniView hotspotIndex={miniViewHotspotIndex} hotspotId={miniViewHotspotId} closeMiniView={closeMiniView} />
          </div>
        ) : null}

        {showMiniView && isPhone && isLandscape ? (
          <div style={miniViewStyles}>
            <MobileMiniView
              hotspotIndex={miniViewHotspotIndex}
              hotspotId={miniViewHotspotId}
              closeMiniView={closeMiniView}
            />
          </div>
        ) : null}

        {showThumbs ? (
          <div style={thumbsStyles}>
            <Thumbs close={closeThumbs} />{' '}
          </div>
        ) : null}
        <div style={{ position: 'relative' }}>
          <div style={navStyles}>&nbsp;</div>
        </div>
      </div>
    </React.Fragment>
  ));

  useEffect(() => {
    const currentScene = allScenes[selectedSceneIndex];
    if (currentScene !== undefined) {
      setCurrentScene(currentScene);
      setSelectedSceneId(currentScene.scene.id);
    }

    setSceneName(currentScene.scene_type_name);
  }, [jsonData.scenes, selectedSceneIndex, setCurrentScene, setSelectedSceneId, allScenes]);

  return (
    <div>
      <Carousel
        ref={targetRef}
        style={{ controlDots: 120 }}
        showThumbs={false}
        selectedItem={selectedSceneIndex}
        statusFormatter={() => {}}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={() => handleLeftArrowClicked(onClickHandler)}
              title={label}
              style={{ ...arrowStyles, left: 15 }}
            >
              <NavigateBeforeIcon style={largeIconStyles} />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={() => handleRightArrowClicked(onClickHandler)}
              title={label}
              style={{ ...arrowStyles, right: 15 }}
            >
              <NavigateNextIcon style={largeIconStyles} />
            </button>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: selectColor }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={renderImages.text}
                key={index}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={() => {
                onClickHandler();
                setSelectedSceneIndex(index);
              }}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="presentation"
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {renderImages}
      </Carousel>
      <FabricViewDialog isSpecial />
    </div>
  );
}
