import Container from '@material-ui/core/Container';
import React, { useEffect, useState } from 'react';
import { TextField, Typography, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FormData from 'form-data';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../components/navigation/NavigationBar';
import api from '../api/api';

const PasswordResetPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [valid, setValid] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [helperTextPasswordRepeat, setHelperTextPasswordRepeat] = useState('');
  const [helperTextPassword, setHelperTextPassword] = useState('');
  const navigate = useNavigate();

  const ACTIVATION_CODE_LENGTH = 32;
  useEffect(() => {
    const vc = new URLSearchParams(window.location.search).get('a_c');
    if (vc) {
      setValidationCode(vc);
    }

    if (vc && vc.length !== ACTIVATION_CODE_LENGTH) {
      setErrorText('DER AKTIVIERUNGSLINK IST INVALIDE');
    }
  }, [validationCode]);

  const changePassword = async () => {
    const userData = await api.getUserByActivationCode(validationCode);
    if (!userData.data) {
      setErrorText('DER AKTIVIERUNGSLINK IST BEREITS ABGELAUFEN');
      return;
    }
    const actualData = userData.data.data.userData;
    const activationCodeTime = actualData.pw_activation_code_time;
    if (!(parseInt(activationCodeTime, 10) * 1000 > Date.now())) {
      setErrorText('DER AKTIVIERUNGSLINK IST BEREITS ABGELAUFEN');
      return;
    }

    if (!checkPassword()) {
      setHelperTextPassword('');
      const formData = new FormData();
      formData.append('password', password);
      formData.append('userId', actualData.userId);
      const user = await api.setNewPassword(formData);
      if (user.status === 200) {
        setValid(true);
        navigate('/');
      }
    }
  };

  const checkPassword = () => {
    if (password === '' || password === undefined) {
      setHelperTextPassword(t('not_empty'));
      return true;
    }
    const regexPasswordLength = /.{8,}/; // test for at least 8 characters
    const regexPasswordContainsUpperCase = /[A-Z]/; // test for uppercase letter
    const regexPasswordContainsLowerCase = /[a-z]/; // test for lowercase letter
    const regexPasswordContainsNumber = /\d/; // test for number
    const regexPasswordContainsSpecialChar = /\W/; // test for special character

    if (!regexPasswordLength.test(password)) {
      setErrorText('Password must be at least 8 characters.');
      return true;
    }

    if (!regexPasswordContainsUpperCase.test(password)) {
      setHelperTextPassword('Password must contain an uppercase character.');
      return true;
    }

    if (!regexPasswordContainsLowerCase.test(password)) {
      setHelperTextPassword('Password must contain an lowercase character.');
      return true;
    }

    if (!regexPasswordContainsNumber.test(password)) {
      setHelperTextPassword('Password must contain a number.');
      return true;
    }

    if (!regexPasswordContainsSpecialChar.test(password)) {
      setHelperTextPassword('Password must contain a special character.');
      return true;
    }

    if (passwordRepeat === '' || passwordRepeat === undefined) {
      setHelperTextPasswordRepeat(t('not_empty'));
      return true;
    }
    if (password !== passwordRepeat) {
      setHelperTextPasswordRepeat('Die Passwörter stimmen nicht überein.');
      return true;
    }
    if (!regexPasswordLength.test(passwordRepeat)) {
      setErrorText('Password must be at least 8 characters.');
      return true;
    }

    if (!regexPasswordContainsUpperCase.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain an uppercase character.');
      return true;
    }

    if (!regexPasswordContainsLowerCase.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain an lowercase character.');
      return true;
    }

    if (!regexPasswordContainsNumber.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain a number.');
      return true;
    }

    if (!regexPasswordContainsSpecialChar.test(passwordRepeat)) {
      setHelperTextPasswordRepeat('Password must contain a special character.');
      return true;
    }
    setHelperTextPassword('');
    setHelperTextPasswordRepeat('');

    return false;
  };

  const resetPassword = async () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
      const response = await api.resetPassword(email);
      if (response.status === 200) {
        setValid(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <Container>
      <NavigationBar />
      <Grid container justify="center">
        {validationCode !== '' ? (
          <Grid xs={6}>
            {errorText !== '' && <h3>{errorText}</h3>}
            <Typography style={{ margin: '20px' }} variant="h6">
              {t('reset_pw')}
            </Typography>
            <TextField
              id="newPassword"
              style={{ width: '90%', margin: '20px 10px' }}
              label={t('new_pw')}
              name="newPassword"
              variant="outlined"
              type="password"
              size="small"
              helperText={helperTextPassword}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <br />
            <TextField
              id="newPasswordRepeat"
              style={{ width: '90%', margin: '20px 10px' }}
              label={t('repeat_new_pw')}
              name="newPasswordRepeat"
              variant="outlined"
              type="password"
              size="small"
              helperText={helperTextPasswordRepeat}
              value={passwordRepeat}
              onChange={(event) => setPasswordRepeat(event.target.value)}
            />
            <Button
              type="submit"
              style={{
                marginTop: 10,
                marginBottom: 10,
                marginRight: 60,
                float: 'right',
                fontSize: '20px',
              }}
              onClick={changePassword}
            >
              {t('change_pw')}
            </Button>
            {valid && <Typography variant="h6">{t('pw_reset_success')}</Typography>}
          </Grid>
        ) : (
          <Grid xs={6}>
            <Typography style={{ margin: '20px 0' }} variant="h6">
              {t('forgot_pw')}
            </Typography>
            <TextField
              id="Email"
              style={{ width: '90%', marginLeft: 0 }}
              label={`${t('email')} * `}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              size="small"
              error={error}
              helperText={error ? t('email_invalid') : ''}
            />
            <Button
              type="submit"
              style={{
                marginTop: 10,
                marginBottom: 10,
                marginRight: 60,
                float: 'right',
                fontSize: '20px',
              }}
              onClick={resetPassword}
            >
              {t('sending')}
            </Button>
            {valid && (
              <Typography variant="h6">
                <br />
                <br />
                {t('pw_reset_link')}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PasswordResetPage;
