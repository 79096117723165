import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { InputAdornment } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { useData } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function InputFieldWithCopyButton({ localCardId, setLink }) {
  const classes = useStyles();
  const { selectedItemsPerIndex, userId, allScenes } = useData();
  const localURI = new URLSearchParams(window.location.search).get('localURI');

  const getSceneIndexById = () => {
    if (localCardId) return allScenes.findIndex((elem) => elem.scene.id === localCardId);
    return 0;
  };

  const getCurrentLink = () => {
    const parentUrl = new URLSearchParams(window.location.search).get('parentURL');

    const baseUrl = window.location !== window.parent.location ? parentUrl : window.location.href;

    if (localURI) {
      const hasUserId = new URLSearchParams(localURI).get('userId');
      const userIdGiven = !hasUserId ? `?userId=${userId}` : '';
      const uri = `${baseUrl}${userIdGiven}${Object.values(selectedItemsPerIndex).reduce(
        (acc, curr, index) => `${acc}&hs${index + 1}=${curr.fabric.post_title}`,
        '',
      )}&scene=${getSceneIndexById()}`;

      setLink(uri);
      return uri;
    }

    const hasUserId = new URLSearchParams(window.location.search).get('userId');
    const userIdGiven = !hasUserId ? `?userId=${userId}` : '';

    const uri = `${baseUrl}${userIdGiven}${Object.values(selectedItemsPerIndex).reduce(
      (acc, curr, index) => `${acc}&hs${index + 1}=${curr.fabric.post_title}`,
      '',
    )}&scene=${getSceneIndexById()}`;

    setLink(uri);
    return uri;
  };

  const handleClick = () => {
    const copyText = document.getElementById('text-input-field-with-adornment');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  return (
    <div>
      <div>
        <FormControl fullWidth className={clsx(classes.margin, classes.textField)}>
          <InputLabel fullWidth htmlFor="standard-adornment-password">
            Link
          </InputLabel>
          <Input
            fullWidth
            id="text-input-field-with-adornment"
            type="text"
            value={getCurrentLink()}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="close">
                  <FileCopyIcon onClick={handleClick} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </div>
  );
}
