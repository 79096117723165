import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import FabricHorizontalPagination from './FabricHorizontalPagination';
import FabricDetailsDialog from './dialog/FabricDetailsDialog';
import { openFabricViewDialog } from '../../Subjects/subjects';
import { useData } from '../../DataProvider';
import { IsPhonePortrait, IsTabletLandScape, IsTabletPortrait } from '../../../util/util';

const tabHeight = '35px';
const useStyles = makeStyles({
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    textTransform: 'capitalize',
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  tab: {
    '& .MuiBox-root': {
      padding: '0px',
    },
  },
  indicator: {
    backgroundColor: (props) => props.selectColor,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box p={3}>
            <Typography component="span" variant="body2">
              {children}
            </Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MiniView({ hotspotIndex, hotspotId, index = 0, closeMiniView, direction }) {
  const isTabletPortrait = IsTabletPortrait();
  const isTabletLandScape = IsTabletLandScape();
  const isPhoneLandScapeSmall = useMediaQuery({ minWidth: 699, maxWidth: 750, orientation: 'landscape' });
  const isPhoneLandScapeBig = useMediaQuery({ minWidth: 750, maxWidth: 950, orientation: 'landscape' });
  const isPhonePortait = IsPhonePortrait();

  let numberOfItems = 9;

  if (isTabletPortrait) {
    numberOfItems = 7;
  } else if (isTabletLandScape) {
    numberOfItems = 7;
  } else if (isPhoneLandScapeSmall) {
    numberOfItems = 4;
  } else if (isPhoneLandScapeBig) {
    numberOfItems = 5;
  } else if (isPhonePortait) {
    numberOfItems = 2;
  }
  const { watchMap, selectColor } = useData();
  const classes = useStyles({ selectColor });
  const [value, setValue] = React.useState(index);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(tabIndex) {
    return {
      id: `simple-tab-${tabIndex}`,
      'aria-controls': `simple-tabpanel-${tabIndex}`,
    };
  }

  function showNumberOfItems() {
    if (watchMap.size > 0) return `(${watchMap.size})`;
    return '';
  }

  return (
    <Paper square>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.indicator,
          }}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="#efc743"
          textColor="#efc743"
          aria-label="icon tabs example"
        >
          <Tab
            classes={{
              root: classes.tabsRoot,
            }}
            label={t('overview')}
            {...a11yProps(0)}
          />
          <Tab
            classes={{
              root: classes.tabsRoot,
            }}
            label={`${t('watchlist')} ${showNumberOfItems()}`}
            aria-label="search"
            {...a11yProps(1)}
          />
          <Tab
            classes={{
              root: classes.tabsRoot,
            }}
            label={t('fabric_detail')}
            aria-label="favorite"
            {...a11yProps(2)}
          />
        </Tabs>
        <div>
          <Button
            onClick={() => {
              openFabricViewDialog({ index: 0, hotspotId });
              closeMiniView();
            }}
            style={{ textTransform: 'capitalize' }}
          >
            {t('filter_fabric')}
          </Button>
          <IconButton aria-label="close" onClick={closeMiniView}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <TabPanel value={value} index={0} classes={{ root: classes.tab }} style={{ padding: 0 }}>
        <FabricHorizontalPagination
          hotspotIndex={hotspotIndex}
          hotspotId={hotspotId}
          todosPerPage={numberOfItems}
          direction={direction}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {watchMap.size > 0 ? (
          <FabricHorizontalPagination
            todosPerPage={numberOfItems}
            isWatchList
            direction={direction}
            hotspotIndex={hotspotIndex}
            hotspotId={hotspotId}
          />
        ) : (
          <div>{t('empty_watchlist_text')}</div>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FabricDetailsDialog closeMiniView={closeMiniView} onClose={() => {}} direction={direction} />
      </TabPanel>
    </Paper>
  );
}

MiniView.propTypes = {
  index: PropTypes.number,
  closeMiniView: PropTypes.func.isRequired,
  direction: PropTypes.string,
};

MiniView.defaultProps = {
  index: 0,
  direction: 'left',
};
