import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useData } from '../DataProvider';
import { getDesignlineById } from '../../util/util';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    display: 'contents',
  },
  details: {
    font: ' normal normal normal 14px/19px Open Sans',
    color: '#414141',
  },
}));

export default function DesignAccordion({ expandedId, setExpandedId, designCount }) {
  const { jsonData, filterItems, setFilterItems, currentScene } = useData();
  const [isExpanded, setIsExpanded] = useState(false);
  const [countElements, setCountElements] = useState(designCount);
  const { designFilter } = filterItems;
  const id = 1;
  const classes = useStyles();

  const selectDesignLine = (designLine) => {
    if (designFilter.includes(designLine.id.toString())) {
      filterItems.designFilter = designFilter.filter((c) => c !== designLine.id.toString());
      setFilterItems({ ...filterItems });
    } else {
      designFilter.push(designLine.id.toString());
      filterItems.designFilter = designFilter;
      setFilterItems({ ...filterItems });
    }
    setCountElements(filterItems.designFilter.length);
  };

  function handleOnChange(expanded) {
    setIsExpanded(expanded);
    setExpandedId(id);
  }

  function isDesignChecked(design) {
    return designFilter.includes(design.id.toString());
  }

  const isEnabled = (design) => {
    let returnValue = true;
    if (filterItems.collectionFilter.length > 0) {
      filterItems.collectionFilter.forEach((colletion) => {
        const relations = currentScene.relation_collection_design[colletion];
        if (relations.includes(design.id.toString())) {
          returnValue = true;
        } else {
          returnValue = false;
        }
      });
    }

    return returnValue;
  };

  useEffect(() => {
    if (expandedId !== id) setIsExpanded(false);
    setCountElements(filterItems.designFilter.length);
  }, [expandedId, designCount, filterItems.designFilter.length]);

  const designValues = [];
  Object.values(currentScene.relation_collection_design).map((item) =>
    item.filter((designLine) => designLine !== '').map((designLine) => designValues.push(designLine)),
  );
  const uniqueItems = [...new Set(designValues)];
  return (
    <div className={classes.root}>
      <Accordion onChange={(event, expanded) => handleOnChange(expanded)} expanded={isExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>
            <Grid container>
              <Grid item xs={11}>
                Designline
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                ({countElements})
              </Grid>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item spacing={1}>
            <Grid item>
              <Grid container>
                {uniqueItems.map((designLineId) => {
                  const designLine = getDesignlineById(designLineId, jsonData);
                  return (
                    designLine && (
                      <Grid item xs={12} sm={4} md={4} key={designLine.post_title}>
                        <FormControlLabel
                          style={{ marginLeft: 15 }}
                          control={
                            <Checkbox
                              checked={isDesignChecked(designLine)}
                              onChange={() => selectDesignLine(designLine)}
                              name="checked"
                              color="primary"
                              key={designLine.post_title}
                              disabled={!isEnabled(designLine)}
                            />
                          }
                          label={designLine.post_title}
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
