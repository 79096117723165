import Container from '@material-ui/core/Container';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CopyToClipboard from '@vigosan/react-copy-to-clipboard';
import {
  Button,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CardActions from '@material-ui/core/CardActions';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import Iframe from 'react-iframe';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

import { Trans, useTranslation } from 'react-i18next';
import { ChromePicker } from 'react-color';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { action } from 'mobx';
import colorIcon from '../assets/colorIcon/colorIcon.png';
import LoggedInNavigationBar from '../components/navigation/LoggedInNavigationBar';
import ClipboardContent from '../components/ClipboardContent';
import { useData } from '../components/DataProvider';
import { getProductGroupByOriginalId } from '../util/util';
import api from '../api/api';
import MuiCardSceneSelection from '../components/EmbedOptions/MuiCardSceneSelection';
import { saveSettings } from '../util/embedUtils';
import WhiteLabelSectionComponent from '../components/Embed/WhiteLabelSectionComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    fontSize: '0.8em',
  },
  expand: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  buttonStyles: {
    fontSize: '20px',
    textTransform: 'none',
    marginRight: '2%',
    marginLeft: '2%',
  },
  typographyStyles: {
    color: '#414141',
    fontSize: '20px',
    marginBottom: '1%',
  },
  coverStyles: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  popoverStyles: {
    position: 'absolute',
    zIndex: '2',
    top: '100%',
  },
  snackbarStyleViaContentProps: {
    backgroundColor: (props) => (props.responseState.isError ? 'red' : props.defaultColor),
  },
}));

const EmbedOptionsPage = observer(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  const defaultColor = theme.palette.select.color;
  const { selectColor, queryParams } = useData();
  const [responseState, setResponseState] = useState({ isError: false, msg: 'no error' });
  const classes = useStyles({ responseState, defaultColor });
  const [openDialog, setOpenDialog] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [possibleProductTypes, setPossibleProductTypes] = useState([]);
  const [isCustomizedSelected, setIsCustomizedSelected] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showHighlightColor, setShowHighlightColor] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const language = new URLSearchParams(window.location.search).get('lang') || localStorage.getItem('i18nextLng');

  const userSelection = useLocalObservable(() => ({
    lang: 'de',
    enableApplicationEmail: '',
    applicationContactEmail: '',
    showEmbedBanner: '',
    showHighlightColor: '',
    whitelabelAppText: '',
    highlightColor: '',
    whiteLabelLogo: '',
    userLogoURL: '',
    iframeWidth: '1920',
    selectedScenes: {},
  }));
  const { search } = useLocation();
  const userId = new URLSearchParams(search).get('loggedInUserId');

  const userConfig = useLocalObservable(() => ({
    userId: new URLSearchParams(search).get('loggedInUserId'),
    parentUserId: new URLSearchParams(search).get('loggedInUserId'),
  }));

  const localSetData = useLocalObservable(() => ({
    data: {},
    checkStates: {},
    productGroups: [],
  }));

  const navigate = useNavigate();

  const [tempUserData, setTempUserData] = useState({});
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [checkState, setCheckState] = useState([]);
  const [indeterminate, setIndeterminate] = useState([]);

  const iframeHost = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  const color = userSelection.highlightColor.substring(1);
  const iframeSrc = `${iframeHost}?userId=${userId}&hs1=${8210}&hs2=${8231}&scene=${0}&color=${color}`;

  const embedCode = `
  <script>
  if (typeof com == "undefined") var com = new Object();
  if (typeof com.sattler_ag == "undefined") com.sattler_ag = new Object();
  com.sattler_ag.mf_config = {lang: '${userSelection.lang}', user_id: '${userId}', breite: '${userSelection.iframeWidth}px', hoehe:  'medium'};
  </script>
  <div id="sattler_mf_iframe_container"><!-- --></div>
  <script src="${process.env.REACT_APP_BASE_URL}wp-content/themes/sattler-design-selector/js/sattler-iframe.js">
  </script>
`;

  const { vertical, horizontal, open } = snackBarState;
  const handleClick = (newSnackBarState) => () => {
    setSnackBarState({ open: true, ...newSnackBarState });
  };
  const handleClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
    setOpenDialog(false);
  };

  const handleExpandClick = () => {
    const invertExpanded = !isExpanded;
    setShowHighlightColor(invertExpanded);
    setIsExpanded(invertExpanded);
    if (!invertExpanded) {
      userSelection.highlightColor = defaultColor;
    }
  };

  const handleChange = (event, index) => {
    const name = `checked${index}`;
    setCheckState({ ...checkState, [name]: event.target.checked });
    setIndeterminate({ ...indeterminate, [name]: false });
    userSelection.selectedScenes[index].forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.isChecked = event.target.checked;
    });
  };

  function updateSceneSelection(obj, productGroupIndex) {
    const index = userSelection.selectedScenes[productGroupIndex].findIndex((el) => el.id === obj.sceneId);
    const name = `checked${productGroupIndex}`;
    userSelection.selectedScenes[productGroupIndex][index] = {
      id: obj.sceneId,
      isChecked: obj.isSelected,
    };
    const newSelectedScenes = { ...userSelection.selectedScenes };

    const allFalse = newSelectedScenes[productGroupIndex].every((el) => el.isChecked === false);
    const allTrue = newSelectedScenes[productGroupIndex].every((el) => el.isChecked === true);
    const someDeselected = newSelectedScenes[productGroupIndex].some((el) => el.isChecked === false);

    if (allFalse) {
      setCheckState({ ...checkState, [name]: false });
      setIndeterminate({ ...indeterminate, [name]: false });
    } else if (allTrue) {
      setCheckState({ ...checkState, [name]: true });
      setIndeterminate({ ...indeterminate, [name]: false });
    } else if (someDeselected) {
      setCheckState({ ...checkState, [name]: false });
      setIndeterminate({ ...indeterminate, [name]: true });
    }
    userSelection.selectedScenes = newSelectedScenes;
  }

  const onSave = () => {
    const ret = saveSettings(userSelection, userConfig);
    if (ret) {
      setShowToast(true);
      setResponseState({ isError: false, msg: 'Data has been stored.' });
    } else {
      setResponseState({
        isError: true,
        msg: `There has been an error occurred.`,
      });
    }
  };

  function isSelected(sceneObject, groupIndex) {
    if (
      Object.entries(userSelection.selectedScenes).length > 0 &&
      Object.entries(userSelection.selectedScenes[groupIndex]).length > 0
    ) {
      const found = userSelection.selectedScenes[groupIndex].find((el) => el.id === sceneObject.scene.id);
      if (found) return found.isChecked;
      return false;
    }
    return true;
  }

  const ProductItems = () => {
    const getPostTitle = (productGroup) => {
      if (localSetData.data) {
        const found = localSetData.data.product_groups.find((el) => el.id === +productGroup[0].product_group);
        if (found) return found.post_title;
      }
      return null;
    };

    return (
      <>
        {localSetData &&
          localSetData.productGroups &&
          localSetData.productGroups.map((productGroup, productGroupIndex) => (
            <Card
              className={classes.root}
              variant="outlined"
              style={{
                border: '1px solid #F4F2F3',
                width: '97.5%',
                marginBottom: 15,
              }}
              key={`productItem-${uuidv4()}`}
            >
              <div>{getPostTitle(productGroup)}</div>
              <Grid container>
                {productGroup.map((el) => (
                  <Grid item key={`cardItem-${uuidv4()}`}>
                    <MuiCardSceneSelection
                      item={el}
                      selectedValue={isSelected(el, productGroupIndex)}
                      updateSceneSelection={updateSceneSelection}
                      borderColor={defaultColor}
                      productGroupIndex={productGroupIndex}
                    />
                  </Grid>
                ))}
              </Grid>
            </Card>
          ))}
      </>
    );
  };

  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        const token = localStorage.getItem('jwt');
        const userData = await api.getUserDataWithAuth(userId, token);
        if (userData.data === 'UserId is not matching!') {
          navigate(`/${queryParams}`);
          return;
        }

        const data = userData.data.data.userData;

        setTempUserData({ ...data });

        userSelection.userLogoURL =
          data.WL_logo !== ''
            ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/whitelabel_logo/${
                data.WL_logo
              }`
            : '';
        userSelection.whitelabelAppText = data.whitelabelapptext;
        userSelection.iframeWidth = data.custom_application_width_type;
        userSelection.applicationContactEmail = data.application_enable_contact_email;
        userSelection.showEmbedBanner = data.show_embedding_banner === 'true';
        userSelection.applicationContactEmail = data.application_contact_email;
        userSelection.enableApplicationEmail = data.application_enable_contact_email === 'true';
        userSelection.highlightColor = data.highlightcolor !== 'undefined' ? data.highlightcolor : selectColor;
        userSelection.lang = data.userlng;
        if (data.highlightcolor !== 'undefined') {
          setShowHighlightColor(data.highlightcolor.toLowerCase() !== '#efc743');
          setIsExpanded(data.highlightcolor.toLowerCase() !== '#efc743');
        }

        try {
          const { data: userSet } = await api.fetchData(data.setId, language);

          const jsonData = userSet.data;
          localSetData.data = jsonData;

          const productGroupIds = jsonData.product_groups.map((p) => p.originalId);

          const productGroups = productGroupIds
            .map((sceneId) =>
              jsonData.scenes
                .filter((scene) => +scene.product_group === sceneId)
                .filter((v, i, a) => a.findIndex((scene) => scene.scene.idWithLang === v.scene.idWithLang) === i),
            )
            .filter((container) => container.length !== 0);

          localSetData.productGroups = productGroups;

          const mappedProductGroups = productGroups.map((group) => group.map((item) => item.product_groupWithLang));

          const uniqueProductTypes = mappedProductGroups.map((groups) => groups[0]);

          setPossibleProductTypes(uniqueProductTypes);

          const numberOfProductGroups = Object.keys(jsonData.product_groups).length;

          let initSceneStates;
          if (data.customer_scenes !== 'undefined' && data.customer_scenes !== '') {
            initSceneStates = productGroups
              .filter((el) => el.length > 0)
              .map((el) =>
                el.map((ele) => ({
                  id: ele.scene.id,
                  isChecked: data.customer_scenes.includes(ele.scene.id),
                })),
              );
          } else {
            initSceneStates = productGroups
              .filter((el) => el.length > 0)
              .map((el) => el.map((ele) => ({ id: ele.scene.id, isChecked: true })));
          }
          userSelection.selectedScenes = initSceneStates;

          const states = [];
          const indeterminateInit = [];

          for (let i = 0; i < numberOfProductGroups; i += 1) {
            states[`checked${i}`] = true;
            indeterminateInit[`checked${i}`] = false;
          }

          for (let j = 0; j < userSelection.selectedScenes.length; j += 1) {
            if (userSelection.selectedScenes[j].every((el) => el.isChecked === false)) {
              states[`checked${j}`] = false;
              indeterminateInit[`checked${j}`] = false;
            } else if (userSelection.selectedScenes[j].every((el) => el.isChecked === true)) {
              states[`checked${j}`] = true;
              indeterminateInit[`checked${j}`] = false;
            } else if (userSelection.selectedScenes[j].some((el) => el.isChecked === false)) {
              states[`checked${j}`] = false;
              indeterminateInit[`checked${j}`] = true;
            }
          }
          setCheckState(states);
          setIndeterminate(indeterminateInit);
        } catch (e) {
          // console.log(e);
        }

        setIsLoaded(true);
      }
    })();
  }, [isLoaded, language, localSetData, navigate, queryParams, selectColor, userId, userSelection]);

  return (
    <Container>
      {isLoaded && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LoggedInNavigationBar />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" className={classes.typographyStyles}>
              {t('embed_code')}
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '1%' }}>
              {t('copy_html_variant1')}
            </Typography>
            <CopyToClipboard
              render={({ copy }) => (
                <ClipboardContent
                  copy={copy}
                  handleClick={handleClick}
                  embedCode={embedCode}
                  borderColor={defaultColor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} align="center">
            {tempUserData && (
              <Card
                className={classes.root}
                variant="outlined"
                style={{
                  border: '1px solid #F4F2F3',
                  width: '88%',
                }}
              >
                <CardContent>
                  <Typography variant="body2" style={{ textAlign: 'left' }}>
                    <b>{t('complete_userData')}</b> <br />
                    <Trans
                      i18nKey="fill_profile_data"
                      t={t}
                      components={[<Link href={`/profile${queryParams}`}>Profildaten</Link>]}
                    />
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid
            container
            item
            xs={7}
            style={{
              overflowY: 'scroll',
              maxHeight: 630,
            }}
          >
            <form>
              <Grid item xs={12}>
                <Card
                  className={classes.root}
                  variant="outlined"
                  style={{
                    border: '1px solid #F4F2F3',
                    width: '100%',
                  }}
                >
                  <CardContent>
                    <Typography
                      color="textSecondary"
                      style={{
                        color: '#414141',
                        fontSize: '20px',
                        marginBottom: '2%',
                      }}
                    >
                      {t('embed_your_ds')}
                    </Typography>

                    <FormControl component="fieldset" style={{ width: '100%' }}>
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#414141',
                          marginTop: '4%',
                          marginBottom: '4%',
                        }}
                      >
                        <Typography variant="body2">
                          <b>{t('fabric_width')}</b>
                        </Typography>
                      </FormLabel>

                      <RadioGroup
                        aria-label="width"
                        name="width"
                        value={userSelection.iframeWidth}
                        onChange={action((e) => {
                          userSelection.iframeWidth = e.target.value;
                        })}
                      >
                        <FormControlLabel
                          value="1920"
                          control={<Radio />}
                          label={`${t('large')} (1920px)`}
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value="920"
                          control={<Radio />}
                          label={`${t('big')} (920px)`}
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value="720"
                          control={<Radio />}
                          label={`${t('medium')} (720px)`}
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value="640"
                          control={<Radio />}
                          label={`${t('small')} (640px)`}
                          style={{ width: 200 }}
                          onChange={() => setIsCustomizedSelected(false)}
                        />
                        <FormControlLabel
                          value={userSelection.iframeWidth}
                          control={<Radio />}
                          checked={isCustomizedSelected}
                          onChange={() => setIsCustomizedSelected(true)}
                          label={
                            <div>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: '12px',
                                }}
                              >
                                {t('userDefined')}
                              </Typography>
                              <TextField
                                id="standard-basic"
                                label={t('fabric_width')}
                                value={userSelection.iframeWidth}
                                onChange={action((e) => {
                                  userSelection.iframeWidth = e.target.value;
                                })}
                                disabled={!isCustomizedSelected}
                                InputProps={{ inputProps: { min: 0, max: 2560 }, endAdornment: 'px' }}
                              />
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography
                      color="textSecondary"
                      style={{
                        color: '#414141',
                        fontSize: '14px',
                        marginBottom: '2%',
                      }}
                    >
                      {t('lang')}
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userSelection.lang}
                      onChange={action((e) => {
                        userSelection.lang = e.target.value;
                      })}
                    >
                      <MenuItem value="de">DE</MenuItem>
                      <MenuItem value="en">EN</MenuItem>
                      <MenuItem value="fr">FR</MenuItem>
                      <MenuItem value="it">IT</MenuItem>
                      <MenuItem value="es">ES</MenuItem>
                      <MenuItem value="se">SE</MenuItem>
                      <MenuItem value="nl">NL</MenuItem>
                      <MenuItem value="tr">TR</MenuItem>
                    </Select>

                    <FormGroup row style={{ marginTop: '4%' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="userRequest"
                            checked={userSelection.enableApplicationEmail}
                            onChange={action(() => {
                              userSelection.enableApplicationEmail = !userSelection.enableApplicationEmail;
                            })}
                          />
                        }
                        label={t('send_user_request')}
                        style={{ width: '100%' }}
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: isExpanded,
                        })}
                        aria-expanded={isExpanded}
                        aria-label="show more"
                      />
                      <Collapse
                        in={userSelection.enableApplicationEmail}
                        timeout="auto"
                        unmountOnExit
                        style={{ width: '100%' }}
                      >
                        <CardContent style={{ padding: '0px' }}>
                          <TextField
                            style={{ width: '90%', marginLeft: '5%' }}
                            id="emailAddressUserRequest"
                            name="emailAddressUserRequest"
                            label={t('email')}
                            variant="outlined"
                            className={classes.textField}
                            size="small"
                            value={userSelection.applicationContactEmail}
                            onChange={action((e) => {
                              userSelection.applicationContactEmail = e.target.value;
                            })}
                          />
                        </CardContent>
                      </Collapse>

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="showEmbedBanner"
                            checked={userSelection.showEmbedBanner}
                            onChange={action(() => {
                              userSelection.showEmbedBanner = !userSelection.showEmbedBanner;
                            })}
                          />
                        }
                        label={t('show_embed')}
                        style={{ width: '100%' }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="individualHighlightcolor"
                            checked={showHighlightColor}
                            onChange={() => {
                              setShowHighlightColor(!showHighlightColor);
                              setIsExpanded(handleExpandClick);
                            }}
                          />
                        }
                        label={t('individual_color')}
                        style={{ width: '100%' }}
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: isExpanded,
                        })}
                        aria-expanded={isExpanded}
                        aria-label="color"
                      />
                      <Collapse in={isExpanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                        <CardActions style={{ padding: '0px', position: 'relative' }}>
                          <Button
                            onClick={() => setDisplayColorPicker(true)}
                            style={{
                              width: '50%',
                              border: '1px solid rgb(244, 242, 243)',
                            }}
                          >
                            <img src={colorIcon} style={{ width: '12%', marginRight: '5%' }} alt="color Icon" />

                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                              {t('select_highlight_color')}
                            </Typography>
                          </Button>
                          {displayColorPicker ? (
                            <div className={classes.popoverStyles}>
                              <div
                                className={classes.coverStyles}
                                onClick={() => setDisplayColorPicker(false)}
                                role="presentation"
                              />
                              <ChromePicker
                                color={userSelection.highlightColor}
                                onChange={action((col) => {
                                  userSelection.highlightColor = col.hex;
                                })}
                                disableAlpha
                              />
                            </div>
                          ) : null}
                        </CardActions>
                      </Collapse>
                      <TextField id="standard-basic" value={userSelection.highlightColor} disabled />
                    </FormGroup>
                  </CardContent>
                  <Card
                    className={classes.root}
                    variant="outlined"
                    style={{
                      border: '1px solid #F4F2F3',
                      width: '97.5%',
                    }}
                  >
                    <CardContent>
                      <Typography variant="body2">
                        <b>{t('Anlagentypen')}</b>
                      </Typography>

                      <Card
                        className={classes.root}
                        variant="outlined"
                        style={{
                          border: '1px solid #F4F2F3',
                          width: '97.5%',
                        }}
                      >
                        <CardContent>
                          {possibleProductTypes.length > 0 &&
                            possibleProductTypes.map((type, index) => (
                              <FormControlLabel
                                key={type}
                                control={
                                  <Checkbox
                                    name={type}
                                    checked={checkState[`checked${index}`]}
                                    indeterminate={indeterminate[`checked${index}`]}
                                  />
                                }
                                label={getProductGroupByOriginalId(type, localSetData.data).post_title}
                                style={{ width: '100%' }}
                                className={clsx(classes.expand, {
                                  [classes.expandOpen]: isExpanded,
                                })}
                                aria-expanded={isExpanded}
                                aria-label="color"
                                onChange={(event) => {
                                  handleChange(event, index);
                                }}
                              />
                            ))}
                        </CardContent>
                      </Card>
                    </CardContent>
                    <Divider />
                    <Grid container>{ProductItems()}</Grid>
                    <Divider />
                    <Grid container item xs={12}>
                      <Typography style={{ fontSize: '14px' }}>
                        {t('support_text')}
                        <br />
                        <a href="mailto:abc@example.com" style={{ color: '#414141' }}>
                          design-selector@sattler-global.com{' '}
                        </a>
                      </Typography>
                      <div
                        style={{
                          width: '100%',
                          fontWeight: 700,
                          marginTop: '1%',
                          marginBottom: '1%',
                        }}
                      >
                        {t('collect_text')}
                        <div style={{ fontWeight: 500 }}>
                          <Trans
                            i18nKey="info_collect_text"
                            t={t}
                            components={[
                              <a href={`${process.env.REACT_APP_BASE_URL}datenschutzerklaerung?lang=${language}`}>
                                {t('data_privacy')}
                              </a>,
                            ]}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Card>

                  <CardActions style={{ float: 'right', width: '55%' }}>
                    <Button className={classes.buttonStyles} onClick={() => setOpenDialog(true)}>
                      {t('preview')}
                    </Button>
                    <Button onClick={onSave} className={classes.buttonStyles}>
                      {t('save_settings')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </form>
          </Grid>

          <Grid container item xs={5} align="flex-start">
            <WhiteLabelSectionComponent saveWL={onSave} userSelection={userSelection} userConfig={userConfig} />
          </Grid>
        </Grid>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="Code wurde erfolgreich kopiert"
        key={vertical + horizontal}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showToast}
        autoHideDuration={6000}
        onClose={() => setShowToast(false)}
        message={responseState.msg}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps,
        }}
      />

      <Dialog open={openDialog} fullWidth maxWidth="xl" onClose={handleClose} aria-labelledby="iframe-dialog">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('preview')}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: '0 auto' }}>
            <Iframe
              url={iframeSrc}
              width={userSelection.iframeWidth}
              height="800"
              display="initial"
              position="relative"
            />
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
});

export default EmbedOptionsPage;
